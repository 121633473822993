import { GetReceiptType, GetReceiptTypeEnum, ILoginArgs } from './models/args.models'
import { apiCaller } from 'services/apiCaller'
import { HomeUrls } from './urls'
import { ILoginResult } from './models/result.model'
import { IReportData } from '../User/models'

export class HomeService {
  async Login(data: ILoginArgs) {
    try {
      const response = await apiCaller.post<ILoginResult>(HomeUrls.login, data)
      return response
    } catch (err) {
      console.log(err)
    }
  }
  async GetReceipt(hashCode: string, getReceipt: GetReceiptType) {
    console.log({ getReceipt })
    try {
      const response = await apiCaller.get<IReportData>(
        HomeUrls.GetReceipt.concat(hashCode).concat(`?getReceipt=${GetReceiptTypeEnum[getReceipt]}`),
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
}
