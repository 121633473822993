export enum UserUrls {
  loginHistory = 'User/LoginHistory',
  changePassword = 'User/changePassword',
  notifications = 'User/user-notifications',
  seenNotifications = 'User/seen-user-notifications',
  userCountryColor = 'User/user-country-color',
  seenUserCountryColor = 'User/seen-user-country-color/',
  CreateReceipt = 'User/CreateReceipt',
  GetReceipt = 'User/GetReceipt',
}
