import { ROUTES } from 'models/enums/routes'
import { Navigate, RouteObject } from 'react-router-dom'
import { MainLayout } from 'templates/mainLayout'
import PanelPage from 'pages/private/panel'
import ReportsPage from 'pages/private/reports'
import HistoryLoginPage from 'pages/private/history-login'
import ChangePasswordPage from 'pages/private/change-password'
import ElectronicReceiptPage from 'pages/public/ElectronicReceipt'
import PrintReportPage from 'pages/private/printReport'

export const privateRoutes: RouteObject[] = [
  {
    path: ROUTES.panel,
    element: <MainLayout />,
    children: [
      {
        path: ROUTES.panel,
        element: <PanelPage />,
      },
      {
        path: ROUTES.reports,
        element: <ReportsPage />,
      },
      {
        path: ROUTES.loginHistory,
        element: <HistoryLoginPage />,
      },
      {
        path: ROUTES.changePassword,
        element: <ChangePasswordPage />,
      },
    ],
  },
  {
    path: ROUTES.electronicReceipt,
    element: <ElectronicReceiptPage />,
  },
  {
    path: ROUTES.printReport,
    element: <PrintReportPage />,
  },
  { path: ROUTES.home, element: <Navigate to={ROUTES.panel} /> },
  { path: '*', element: <Navigate to={ROUTES.panel} /> },
]
