import { Space, Typography } from 'antd'

const { Title } = Typography

const LoginTitle = () => {
  return (
    <Space direction="vertical" size={8}>
      <Title className="title">
        Welcome to <span className="turnosadra">ECZ Bank</span>
      </Title>
    </Space>
  )
}

export default LoginTitle
