import styled from 'styled-components'

export const ContentContainer = styled.div`
  overflow-x: hidden;
  height: calc(100vh - 64px);
  background: ${props => props.theme.colors.base};

  padding: 16px 8px;

  .video {
    position: fixed;
    width: 100%;
    top: 0;
    bottom: 0;
    height: 100%;
  }
  @media only screen and (max-width: 768px) {
    padding: 16px 0px 0px;
  }
`
