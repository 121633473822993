import { ColumnsType } from 'antd/lib/table/interface'
import TableUi from 'components/Ui/table'
import { rectQueryEndPoints } from 'constants/rectQueryEndPoints'
import { PaginationData } from 'models/enums'
import { IObject } from 'models/interfaces'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { UserService } from 'services/controllers/User/User.service'
import { IArgsLoginHistory } from 'services/controllers/User/models'

const HistoryLogin = () => {
  const services = new UserService()
  const [pagination, setPagination] = useState<IArgsLoginHistory>({
    Limit: PaginationData.Limit,
    Offset: PaginationData.Offset,
  })
  const column: ColumnsType<IObject> = [
    {
      title: '#',
      key: 'index',
      width: '20px',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'روز',
      dataIndex: 'day',
      key: 'day',
      render(value, record, index) {
        return new Date(record.timeStamp).toLocaleDateString('fa-IR', {
          weekday: 'long',
        })
      },
    },
    {
      title: 'تاریخ',
      dataIndex: 'date',
      render(value, record, index) {
        return new Date(record.timeStamp).toLocaleDateString('fa-IR')
      },
    },
    {
      title: 'ساعت',
      dataIndex: 'hour',
      render(value, record, index) {
        return new Date(record.timeStamp).toLocaleTimeString('fa-IR', {
          hour: '2-digit',
          minute: '2-digit',
        })
      },
    },
    {
      title: 'نوع دستگاه',
      dataIndex: 'deviceType',
    },
    {
      title: 'IP آدرس',
      dataIndex: 'ip',
    },
  ]

  const fetcher = async (pagination: IArgsLoginHistory) => {
    const res = await services.getLoginHistory(pagination)
    return res?.data
  }

  const { data, isLoading } = useQuery([rectQueryEndPoints.bankAccount, pagination], () => fetcher(pagination))

  return (
    <section className="w-full max-w-[900px] m-auto">
      <TableUi
        rowKey={'timeStamp'}
        loading={isLoading}
        scroll={{ x: 'auto' }}
        columns={column}
        dataSource={data?.records}
        pagination={{
          position: ['bottomRight'],
          pageSize: pagination.Limit,
          pageSizeOptions: [5, 10, 15],
          showSizeChanger: true,
          total: data?.count,
          current: pagination.Offset,
          onChange: (page, pageSize) => {
            setPagination({
              Limit: pageSize,
              Offset: page,
            })
          },
        }}
      />
    </section>
  )
}

export default HistoryLogin
