import { Col, Form, Row, Space, Typography } from 'antd'
import { Rule } from 'antd/es/form'
import { useForm } from 'antd/lib/form/Form'
import TextWarning from 'components/TextWarning'
import ButtonUiKit from 'components/Ui/Button'
import FormUiKit from 'components/Ui/Form'
import InputPassword from 'components/Ui/inputPassword'
import { Icons } from 'components/customIcons'
import { IObject } from 'models/interfaces'
import React, { useState } from 'react'
import { UserService } from 'services/controllers/User/User.service'
import { IArgsChangePassword } from 'services/controllers/User/models'
import { ChangePasswordWrapper } from './styles'
import { checkPasswordStrength } from './helper'
import { SwalََAlert } from 'components/alert'
import useAuthStore from 'store/auth'
import { useNavigate } from 'react-router'
import { ROUTES } from 'models/enums'
const { Title, Text } = Typography

// ____________________________________________
export interface IPasswordStatus {
  lowerLetters: boolean
  upperLetters: boolean
  numbers: boolean
  symbols: boolean
  characterLength: boolean
}
type StrongPassword = 'week' | 'normal' | 'strong' | undefined

const ChangePassword = () => {
  const navigate = useNavigate()
  const { login } = useAuthStore()
  const [formControl] = useForm()
  const service = new UserService()
  const [showPasswordChecker, setShowPasswordChecker] = useState<boolean>(false)

  const changePasswordReq = async (data: IArgsChangePassword) => {
    const res = await service.changePassword(data)
    if (res?.status !== 200) return
    res.data.token && login(res.data.token)
    SwalََAlert({
      type: 'success',
      text: 'تغییر رمز عبور با موفقیت انجام شد',
      onOk() {
        navigate(ROUTES.home)
      },
    })
  }

  const onSubmit = (values: IObject) => {
    const params: IArgsChangePassword = {
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
    }

    changePasswordReq(params)
  }

  const [passwordStrength, setPasswordStrength] = useState<StrongPassword>()
  const [passwordStatus, setPasswordStatus] = useState<IPasswordStatus>({
    lowerLetters: false,
    upperLetters: false,
    numbers: false,
    symbols: false,
    characterLength: false,
  })

  const extraNewPassword = (
    <TextWarning>رمز عبور باید حداقل از 8 کاراکتر انگلیسی و ترکیبی از حروف بزرگ و کوچک ، اعداد و نمادها باشد</TextWarning>
  )

  const repeatPasswordRules: Rule[] = [
    { required: true, message: 'لطفا تکرار رمز عبور را وارد نمایید' },

    () => ({
      validator(_, value) {
        if (value !== formControl.getFieldValue('newPassword')) {
          return Promise.reject(new Error('رمز عبور مطابقت ندارد'))
        }

        return Promise.resolve()
      },
    }),
  ]

  const passwordHandler = (password: string) => {
    const { force, status } = checkPasswordStrength(password)
    setPasswordStatus(status)
    setPasswordStrength(!force ? undefined : force <= 20 ? 'week' : force <= 40 ? 'normal' : force > 40 ? 'strong' : undefined)
  }

  return (
    <ChangePasswordWrapper>
      <FormUiKit form={formControl} onFinish={onSubmit} layout="vertical">
        <Space size={28} direction="vertical" className="w-full h-full wrapper">
          <Col span={24}>
            <Title level={5} className="title">
              تغییر رمز عبور
            </Title>
            <Text className="description">
              برای تغییر رمز عبور ، ابتدا رمز عبور فعلی را وارد کرده و سپس اقدام به تعیین رمز عبور جدید کنید
            </Text>
          </Col>

          <Col>
            <Form.Item required={false} name={'currentPassword'} rules={[{ message: 'رمز عبور فعلی خود را وارد کنید', required: true }]}>
              <InputPassword label="رمز عبور فعلی" />
            </Form.Item>
          </Col>

          <Col>
            <div className={`password-strength flex gap-2 items-center justify-center  ${passwordStrength && 'active '}`}>
              <p
                style={{
                  color:
                    passwordStrength === 'week'
                      ? '#F04849'
                      : passwordStrength === 'normal'
                      ? '#EC8A2A'
                      : passwordStrength === 'strong'
                      ? '#25E3BF'
                      : '#56575B',
                }}
              >
                {passwordStrength === 'week'
                  ? 'ضعیف'
                  : passwordStrength === 'normal'
                  ? 'متوسط'
                  : passwordStrength === 'strong'
                  ? 'قوی'
                  : ''}
              </p>
              <div className="flex justify-center items-center gap-1">
                <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <line
                    x1="12"
                    y1="1"
                    y2="1"
                    stroke={
                      passwordStrength === 'week'
                        ? '#F04849'
                        : passwordStrength === 'normal'
                        ? '#EC8A2A'
                        : passwordStrength === 'strong'
                        ? '#25E3BF'
                        : '#56575B'
                    }
                    strokeWidth="2"
                  />
                </svg>
                <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <line
                    x1="12"
                    y1="1"
                    y2="1"
                    stroke={passwordStrength === 'normal' ? '#EC8A2A' : passwordStrength === 'strong' ? '#25E3BF' : '#56575B'}
                    strokeWidth="2"
                  />
                </svg>
                <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <line x1="12" y1="1" y2="1" stroke={passwordStrength === 'strong' ? '#25E3BF' : '#56575B'} strokeWidth="2" />
                </svg>
              </div>
            </div>
            <Form.Item
              extra={extraNewPassword}
              name={'newPassword'}
              required={false}
              rules={[
                { required: true },
                () => ({
                  validator(_, value) {
                    if (!value || passwordStrength === 'strong') {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error('رمزعبور وارد شده باید قوی باشد.'))
                  },
                }),
              ]}
            >
              <InputPassword
                label=" رمز عبور جدید"
                onFocus={() => {
                  setShowPasswordChecker(true)
                }}
                onBlur={() => {
                  setShowPasswordChecker(false)
                }}
                onChange={e => passwordHandler(e.target.value)}
                allowClear
              />
            </Form.Item>
            <div className={`password-checker-container  ${showPasswordChecker && 'active-password-checker'}`}>
              <div className="flex justify-start items-center gap-1">
                <Icons name={passwordStatus.characterLength ? 'checked' : 'close'} />
                <p>حداقل 8 کاراکتر</p>
              </div>
              <div className="flex justify-start items-center gap-1">
                <Icons name={passwordStatus.upperLetters ? 'checked' : 'close'} />
                <p>استفاده از حروف بزرگ</p>
              </div>
              <div className="flex justify-start items-center gap-1">
                <Icons name={passwordStatus.lowerLetters ? 'checked' : 'close'} />

                <p>استفاده از حروف کوچک</p>
              </div>
              <div className="flex justify-start items-center gap-1">
                <Icons name={passwordStatus.numbers ? 'checked' : 'close'} />
                <p>استفاده از اعداد</p>
              </div>
              <div className="flex justify-start items-center gap-1">
                <Icons name={passwordStatus.symbols ? 'checked' : 'close'} />
                <p>استفاده از نماد ها</p>
              </div>
            </div>
          </Col>
          <Form.Item required={false} name={'repeatPassword'} rules={repeatPasswordRules}>
            <InputPassword label="تکرار رمز عبور جدید" />
          </Form.Item>
          <ButtonUiKit htmlType="submit" className="btn" loading={false} type="primary">
            ثبت
          </ButtonUiKit>
        </Space>
      </FormUiKit>
    </ChangePasswordWrapper>
  )
}

export default ChangePassword
