export interface ILoginArgs {
  email: string
  password: string
}

export interface IGetReceipt {
  hashCode: string
  type: 1 | 2
}
export enum GetReceiptTypeEnum {
  qrCode = 1,
  pdf = 2,
}
export type GetReceiptType = keyof typeof GetReceiptTypeEnum
