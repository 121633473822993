import { Space, Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'

const LoginLogo = () => {
  return (
    <Space size={5} className="flex items-center justify-center">
      <CompanyName className="title">
        <span className="turnosadra" style={{ fontSize: 48 }}>
          ECZ Bank
        </span>
      </CompanyName>
      {/* <img src={'/assets/images/loginLogo.svg'} alt="logo" /> */}
    </Space>
  )
}

export default LoginLogo

const CompanyName = styled(Typography.Text)``
