import { FC, useEffect } from 'react'
import './style.css'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'models/enums'
export const NotFound: FC = () => {
  const navigate = useNavigate()
  useEffect(() => {
    // const redirect = setTimeout(() => {
    //   navigate(ROUTES.home)
    // }, 1500)
    // return () => {
    //   clearTimeout(redirect)
    // }
  })
  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1>404</h1>
          <h2>Page not found</h2>
        </div>
      </div>
    </div>
  )
}
