import { privateRoutes } from './private'
import { publicRoutes } from './public'

const useRoutesDefinition = () => {
  const routesDefinition = {
    public: publicRoutes,
    private: privateRoutes,
  }

  return { routesDefinition }
}

export default useRoutesDefinition
