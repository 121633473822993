import { ROUTES } from 'models/enums'
import Swal from 'sweetalert2'

const responseHandler = (res: any, type: 'success' | 'error') => {
  if (type === 'success') {
    if (res.status >= 200 && res.status < 300) {
      Swal.fire({
        title: 'ok',
        text: res.data?.message,
        color: '#25E3BF',
        confirmButtonText: 'close',
        background: '#0b0e11',
        iconHtml: '<img src="/assets/images/sweet-alert/sweetalert-success.png" width="53px" height="53px">',
      }).then(() => res)
    }
  } else {
    const { response } = res
    if (response?.data) {
      if (typeof response.data === 'string') {
        return Swal.fire({
          title: 'Error',
          text: response?.data,
          color: '#F04849',
          confirmButtonText: 'close',
          background: '#0b0e11',
          iconHtml: '<img src="/assets/images/sweet-alert/sweetalert-error.png" width="53px" height="53px">',
        })
      }
      if (response.data.errors) {
        if (typeof response.data.errors === 'string') {
          return Swal.fire({
            title: 'Error',
            text: response.data.errors,
            color: '#F04849',
            confirmButtonText: 'close',
            background: '#0b0e11',
            iconHtml: '<img src="/assets/images/sweet-alert/sweetalert-error.png" width="53px" height="53px">',
          })
        }
        Object.keys(response.data.errors).forEach((item: string) => {
          if (item) {
            return Swal.fire({
              text: `${response.data.errors[item].map((text: string, index: number) =>
                response.data.errors[item].length - 1 !== index ? text.concat(' -') : text,
              )}`,
              title: 'Error',
              color: '#F04849',
              confirmButtonText: 'close',
              background: '#0b0e11',
              iconHtml: '<img src="/assets/images/sweet-alert/sweetalert-error.png" width="53px" height="53px">',
            })
          }
        })
      }
      if (typeof response.data === 'string') {
        return Swal.fire({
          title: 'Error',
          text: response.data.errors,
          color: '#F04849',
          confirmButtonText: 'close',
          background: '#0b0e11',
          iconHtml: '<img src="/assets/images/sweet-alert/sweetalert-error.png" width="53px" height="53px">',
        })
      }
      Object.keys(response.data).forEach((item: string) => {
        if (item) {
          return Swal.fire({
            text: `${response?.data[item]?.map((text: string, index: number) =>
              response.data[item].length - 1 !== index ? text.concat(' -') : text,
            )}`,
            title: 'Error',
            color: '#F04849',
            confirmButtonText: 'close',
            background: '#0b0e11',
            iconHtml: '<img src="/assets/images/sweet-alert/sweetalert-error.png" width="53px" height="53px">',
          })
        }
      })
    }
    if (!response && res.code === 'ERR_NETWORK') {
      Swal.fire({
        text: 'Internet connection error, try again.',
        title: 'Error',
        color: '#F04849',
        confirmButtonText: 'close',
        background: '#0b0e11',
        iconHtml: '<img src="/assets/images/sweet-alert/sweetalert-error.png" width="53px" height="53px">',
      })
      return false
    }
    if (!response) {
      Swal.fire({
        text: 'Ops, Something happened. Please try again',
        title: 'Error',
        color: '#F04849',
        confirmButtonText: 'close',
        background: '#0b0e11',
        iconHtml: '<img src="/assets/images/sweet-alert/sweetalert-error.png" width="53px" height="53px">',
      })
      return false
    }
    if (response.status === 401 || response.status === 403) {
      Swal.fire({
        text: 'You do not have permission to access',
        title: 'Error',
        color: '#F04849',
        confirmButtonText: 'close',
        background: '#0b0e11',
        iconHtml: '<img src="/assets/images/sweet-alert/sweetalert-error.png" width="53px" height="53px">',
      }).then(() => {
        window.location.replace(ROUTES.login)
      })

      return 401
    }
    if (response.status === 500) {
      Swal.fire({
        text: 'Response error from the server side',
        title: 'Error',
        color: '#F04849',
        confirmButtonText: 'close',
        background: '#0b0e11',
        iconHtml: '<img src="/assets/images/sweet-alert/sweetalert-error.png" width="53px" height="53px">',
      })
      return false
    }
    if (response.status >= 500) {
      Swal.fire({
        text: 'Response error from the server side',
        title: 'Error',
        color: '#F04849',
        confirmButtonText: 'close',
        background: '#0b0e11',
        iconHtml: '<img src="/assets/images/sweet-alert/sweetalert-error.png" width="53px" height="53px">',
      })
      return false
    }

    return response
  }
}

export default responseHandler
