import { ROUTES } from 'models/enums'
import { Dispatch, FC, SetStateAction } from 'react'
import { useLocation } from 'react-router-dom'
import { MainLayoutHeaderContainer } from '../styles/header'
interface IProps {
  setOpen: Dispatch<SetStateAction<boolean>>
}
const MainLayoutHeader: FC<IProps> = ({ setOpen }) => {
  const location = useLocation()
  return (
    <MainLayoutHeaderContainer homepage={(location.pathname === ROUTES.home).toString()}>
      <span className="material-icons" onClick={() => setOpen(prev => !prev)}>
        menu
      </span>
      <div className="" style={{ textAlign: 'left' }} dir="ltr">
        ECZ Bank{' '}
      </div>
    </MainLayoutHeaderContainer>
  )
}

export default MainLayoutHeader
