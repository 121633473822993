import axios from 'axios'
import useRoutesDefinition from './definition'
import useAuthStore from 'store/auth'
import { useEffect, useState } from 'react'
import { definitionRoutes } from './types/definitionRoutes'
import { Skeleton } from 'antd'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

export const Routers: React.FC = (): JSX.Element => {
  const { routesDefinition } = useRoutesDefinition()
  const { token } = useAuthStore()
  const [currentRoutes, setCurrentRoutes] = useState<definitionRoutes>(token ? 'private' : 'public')

  useEffect(() => {
    setCurrentRoutes(token ? 'private' : 'public')
    axios.defaults.headers.common['Authorization'] = 'Bearer '.concat(token!)
  }, [token])

  if (!currentRoutes) return <Skeleton />

  const routes = createBrowserRouter([...routesDefinition[currentRoutes]])

  return <RouterProvider router={routes} />
}
