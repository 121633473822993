import { breakPointsMd, breakPointsSm } from 'constants/screen'
import styled from 'styled-components'

export const ChangePasswordWrapper = styled.section`
  padding: 24px;
  gap: 24px;
  max-width: 374px;
  min-height: 525px;
  background: ${props => props.theme.scroll.scrollTrack};
  mix-blend-mode: normal;
  box-shadow: 0px 13.3077px 53.2308px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  margin: auto;

  .material-icons {
    color: ${props => props.theme.colors.disable};
  }

  .password-checker-container {
    position: absolute;
    left: 0;
    transition: 0.3s;
    width: 0;
    height: 0;
    top: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 8px;
    background: ${props => props.theme.colors.secondary};
    border: 1px solid ${props => props.theme.colors.inputBorder};
    box-shadow: -6px -10px 10px ${props => props.theme.colors.boxShadow};
    border-radius: 12px;
    z-index: -100;
    opacity: 0;

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      text-align: left;
      color: ${props => props.theme.colors.text.white};
      margin-bottom: unset;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .active-password-checker {
    width: 149px !important;
    height: 103px !important;
    left: -153px;
    z-index: 100;
    opacity: 1;
    top: 0;
  }

  .password-strength {
    position: absolute;
    transition: 0.3s;
    top: 17px;
    left: 8px;
    z-index: -100;

    &.active {
      top: -17px;
      z-index: 100;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      display: flex;
      align-items: center;
      text-align: left;
      margin-bottom: 0;
    }
  }

  .btn {
    width: 100%;
    justify-content: center;
    height: 44px;
  }

  @media only screen and (max-width: 456px) {
    border-radius: 0px;
    max-width: 100%;
    height: 100%;

    .wrapper {
      justify-content: center;
    }
  }

  @media only screen and (max-width: ${breakPointsSm}) {
    padding: 16px;
  }
  @media only screen and (max-width: ${breakPointsMd}) {
    .active-password-checker {
      z-index: 100;
      opacity: 1;
      top: 0;
      bottom: 0;
    }
  }
`
