import { Dispatch, FC, SetStateAction } from 'react'
import { Drawer, Menu } from 'antd'
import { useLocation } from 'react-router-dom'
import { useMenuItems } from '../constants/sidebarItems'
import { ROUTES } from 'models/enums'
import { MenuContainer } from '../styles/sidebar'
import DarkTheme from 'styles/theme/DarkTheme'
interface IProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}
const Sidebar: FC<IProps> = ({ open, setOpen }) => {
  const location = useLocation()
  const { items } = useMenuItems(setOpen)

  const currentPath = location.pathname

  return (
    <MenuContainer>
      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        style={{
          backgroundColor: DarkTheme.colors.components.footerBg,
        }}
        closable
        placement="left"
      >
        <Menu
          defaultSelectedKeys={[ROUTES.panel]}
          mode="inline"
          theme="dark"
          items={items}
          className="menu"
          selectedKeys={[currentPath]}
          style={{
            backgroundColor: DarkTheme.colors.components.footerBg,
          }}
        />
      </Drawer>
    </MenuContainer>
  )
}

export default Sidebar
