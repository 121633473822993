import styled from 'styled-components'

export const MainLayoutContainer = styled.div`
  display: flex;
  .ant-layout-header {
    background-color: ${props => props.theme.colors.base};
  }
  .ant-layout-sider-children {
    padding-top: 40px;
    background: ${props => props.theme.colors.components.footerBg} !important;
  }
  .ant-menu-item {
    border: none;
    margin-top: 32px;
  }
`
