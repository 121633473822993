import { Col, Row } from 'antd'
import styled from 'styled-components'

export const ReportsFiltersWrapper = styled(Row)`
  padding: 24px 16px;
  max-width: 1376px;
  width: 100%;
  margin: 0 auto !important;
  background: #23272e;
  mix-blend-mode: normal;
  border-right: 1px solid rgba(86, 87, 91, 0.3);
  box-shadow: 0px 13.3077px 53.2308px rgba(0, 0, 0, 0.05);
  border-radius: 16px;

  .ant-select {
    width: 100%;
  }

  .searchBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 11px 24px;
    max-width: 205px;
    width: 100%;
    height: 44px;
    border-radius: 8px;
  }
`
export const DateHolder = styled(Col)<{ dateLabel: string }>`
  .date {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 12px;
    gap: 8px;
    margin-bottom: unset !important;
    height: 45px;

    /* Dark Mode/Border */

    border: 1px solid #56575b;
    border-radius: 8px;
    input::placeholder {
      font-size: 10px;
    }
    position: relative;
    &::before {
      content: 'بازه زمانی ثبت نام';
      position: absolute;
      font-weight: 400;
      line-height: 1.4375em;
      -webkit-letter-spacing: 0.00938em;
      -moz-letter-spacing: 0.00938em;
      -ms-letter-spacing: 0.00938em;
      letter-spacing: 0.00938em;
      padding: 0;
      display: block;
      -webkit-transform-origin: top left;
      -ms-transform-origin: top left;
      transform-origin: top left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100% - 24px);
      position: absolute;
      right: 8px;
      top: -10px;
      color: rgba(148, 148, 148, 1);
      z-index: 1;
      pointer-events: auto;
      font-size: 0.85em;
      background: ${props => props.theme.colors.secondary};
    }
  }
`
