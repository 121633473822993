import styled from 'styled-components'

export const ReportTableWrapper = styled.section`
  position: relative;
  width: 100%;
  margin: 0 auto;
  .output {
    position: absolute;
    right: 20px;
    bottom: 35px;
  }

  .ant-table-row-expand-icon-cell {
    padding-right: 0 !important;
  }

  .ant-pagination-options {
    margin-left: auto !important;
    margin-right: 50px !important;
  }

  .ant-pagination {
    align-items: center;
  }
  .ant-table-cell-fix-right-first {
    z-index: 100;
    background: ${props => props.theme.colors.components.headerBg} !important;
  }
  .ant-table-tbody > tr > td {
    border-bottom: unset;
  }
  .ant-table-ping-left {
    .ant-table-cell-fix-right.ant-table-cell-fix-right-first {
      background: transparent !important;
      box-shadow: unset;
      transition: all 0.5s;
    }
  }
  .ant-table-ping-right {
    th.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
      background: #23272e !important;
      box-shadow: 0 10px 10px ${props => props.theme.colors.primary};
    }
    td.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
      background: #171a1f !important;
      box-shadow: 0 10px 10px ${props => props.theme.colors.primary};
    }
  }
  .ant-table-ping-left.ant-table-ping-right {
    /* background: ${props => props.theme.colors.components.headerBg} !important; */
    /* .ant-table-cell-fix-right.ant-table-cell-fix-right-first {
      background: ${props => props.theme.colors.components.footerBg} !important;
    } */
  }
`
