import { rectQueryEndPoints } from 'constants/rectQueryEndPoints'
import { useQuery } from 'react-query'
import { UserService } from 'services/controllers/User/User.service'
import { ReportsService } from 'services/controllers/reports/Reports.service'
import { IPaginationParams } from 'services/model'

export const useGetReceipt = (params: IPaginationParams) => {
  const { GetReceipt } = new UserService()

  const fetcher = async () => {
    const res = await GetReceipt(params)
    return res?.data
  }
  const { data, isLoading } = useQuery([rectQueryEndPoints.reports, params], fetcher)

  return { reports: data, loadingReports: isLoading }
}
