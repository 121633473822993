import {
  IArgsChangePassword,
  IArgsGetNotifications,
  IArgsLoginHistory,
  IArgsSeenNotification,
  IColorSourceType,
  IElectronicReceiptData,
} from './models/args.models'
import { apiCaller } from 'services/apiCaller'
import { UserUrls } from './urls'
import { IDataModel, IPaginationParams } from 'services/model'
import { ILoginHistory, INotifications, IReportData, IUserCountryColor } from './models'
import { ILoginResult } from '../Home/models'

export class UserService {
  async getLoginHistory(params?: IArgsLoginHistory) {
    try {
      const response = await apiCaller.get<IDataModel<ILoginHistory[]>>(UserUrls.loginHistory, { params: params })
      return response
    } catch (err) {
      console.log(err)
    }
  }

  async changePassword(data: IArgsChangePassword) {
    try {
      const response = await apiCaller.post<ILoginResult>(UserUrls.changePassword, data)
      return response
    } catch (err) {
      console.log(err)
    }
  }
  async GetNotifications(params?: IArgsGetNotifications) {
    try {
      const response = await apiCaller.get<IDataModel<INotifications[]>>(UserUrls.notifications, { params: params })
      return response
    } catch (err) {
      console.log(err)
    }
  }
  async SeenNotifications(data: IArgsSeenNotification) {
    try {
      const response = await apiCaller.post<void>(UserUrls.seenNotifications, data)
      return response
    } catch (err) {
      console.log(err)
    }
  }
  async userCountryColor(colorSourceType: IColorSourceType) {
    try {
      const response = await apiCaller.get<IUserCountryColor[]>(UserUrls.userCountryColor.concat(`?colorSourceType=${colorSourceType}`))
      return response
    } catch (err) {
      console.log(err)
    }
  }
  async seenUserCountryColor(cid: string) {
    try {
      const response = await apiCaller.post<void>(UserUrls.seenUserCountryColor.concat(cid), undefined, undefined, false)
      return response
    } catch (err) {
      console.log(err)
    }
  }
  async CreateReceipt(data: IElectronicReceiptData) {
    try {
      const response = await apiCaller.post<void>(UserUrls.CreateReceipt, data)
      return response
    } catch (err) {
      console.log(err)
    }
  }

  async GetReceipt(params: Partial<IPaginationParams>) {
    try {
      const response = await apiCaller.get<IDataModel<IReportData[]>>(UserUrls.GetReceipt, {
        params: params,
      })
      return response
    } catch (err) {
      console.log(err)
    }
  }
}
