import axios, { AxiosRequestConfig } from 'axios'
import responseHandler from './responseHandler'

export const apiCaller = {
  get: async <T>(url: string, config?: AxiosRequestConfig<any>) => {
    try {
      const res = await axios.get<T>(url, config)
      if (res.status >= 200 && res.status < 300) {
        return res
      }
    } catch (err) {
      try {
        const res = await axios.get<T>(url, config)
        if (res.status >= 200 && res.status < 300) {
          return res
        }
      } catch (err) {
        responseHandler(err, 'error')
      }
    }
  },
  post: async <T>(url: string, data?: any, config?: AxiosRequestConfig<any>, hasRequestHandler: boolean = true) => {
    try {
      const res = await axios.post<T>(url, data, config)

      if (res.status >= 200 && res.status < 300) {
        return res
      }
    } catch (err) {
      hasRequestHandler && responseHandler(err, 'error')
    }
  },
  put: async <T>(url: string, data: any, config?: AxiosRequestConfig<any>) => {
    try {
      const res = await axios.put<T>(url, data, config)
      if (res.status >= 200 && res.status < 300) {
        return res
      }
    } catch (err) {
      responseHandler(err, 'error')
    }
  },
  delete: async <T>(url: string, id: number) => {
    try {
      const res = await axios.delete<T>(url, { data: { id } })
      if (res.status >= 200 && res.status < 300) {
        return res
      }
    } catch (err) {
      responseHandler(err, 'error')
    }
  },
}
