import { Layout } from 'antd'
import styled from 'styled-components'
const { Content } = Layout

export const LoginLayoutStyled = styled(Content)`
  background: url('/assets/images/loginBg.svg') no-repeat center/cover;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
