import { FC } from 'react'
import { LoadingStyle } from './styles'
import { Spin } from 'antd'

interface IProp {
  loading?: boolean
}
export const Loading: FC<IProp> = ({ loading }) => {
  return (
    <LoadingStyle className="loading">
      <h1>please wait...</h1>
      <Spin spinning={loading || true} />
    </LoadingStyle>
  )
}
