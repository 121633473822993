export const checkPasswordStrength = (p: string) => {
  // 1
  let force = 0
  // 2
  const regex = /[-@!#$%^&*()_+|~=`{}\[\]:";'<>?,.\/]+/g
  const lowerLetters = /[a-z]+/.test(p)
  const upperLetters = /[A-Z]+/.test(p)
  const numbers = /[0-9]+/.test(p)
  const symbols = regex.test(p)
  const characterLength = p.length >= 8
  // 3
  const flags = [lowerLetters, upperLetters, numbers, symbols, characterLength]
  const status = {
    lowerLetters,
    upperLetters,
    numbers,
    symbols,
    characterLength,
  }
  // 4
  let passedMatches = 0
  for (const flag of flags) {
    passedMatches += flag === true ? 1 : 0
  }

  // 5
  force += 2 * p.length + (p.length >= 8 ? 1 : 0)
  force += passedMatches * 10

  // 6
  force = p.length <= 6 ? Math.min(force, 10) : force

  // 7
  force = passedMatches === 1 ? Math.min(force, 10) : force
  force = passedMatches === 2 ? Math.min(force, 20) : force
  force = passedMatches === 3 ? Math.min(force, 30) : force
  force = passedMatches === 4 ? Math.min(force, 40) : force
  force = passedMatches === 5 ? Math.min(force, 50) : force
  return { force, status }
}
