import { USER_JWT_TOKEN } from 'constants/localStorage'
import create from 'zustand'

interface IStore {
  token?: string
  login: (token: string) => void
  logout: () => void
}

const useAuthStore = create<IStore>(set => ({
  token: localStorage.getItem(USER_JWT_TOKEN) || undefined,
  login: token => set(() => ({ token: token })),
  logout: () => set({ token: undefined }),
}))

export default useAuthStore
