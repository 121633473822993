export const rectQueryEndPoints = {
  currencies: 'currencies',
  owners: 'owners',
  banks: 'banks',
  countries: 'countries',
  cashes: 'cashes',
  offices: 'offices',
  bankAccount: 'bankAccount',
  customers: 'customers',
  reports: 'reports',
  notifications: 'notifications',
  fund: 'fund',
  getBalanceToUSD: 'GetBalanceToUSD',
  userCountryColor: 'userCountryColor',
  getCountryHasOffice: 'getCountryHasOffice',
  getCountryHasCurrency: 'getCountryHasCurrency',
}
