export enum DataEntryUrls {
  newCustomer = 'DataEntry/NewCustomer',
  getCustomer = 'DataEntry/GetCustomer',
  newCountry = 'DataEntry/NewCountry',
  getCountry = 'DataEntry/GetCountry',
  newOwner = 'DataEntry/NewOwner',
  getOwner = 'DataEntry/GetOwner',
  newCash = 'DataEntry/NewCash',
  getCashes = 'DataEntry/GetCash',
  newBank = 'DataEntry/NewBank',
  getBank = 'DataEntry/GetBank',
  newCurrency = 'DataEntry/NewCurrency',
  getCurrency = 'DataEntry/GetCurrency',
  getBankAccount = 'DataEntry/GetBankAccount',
  newBankAccount = 'DataEntry/NewBankAccount',
  newOffice = 'DataEntry/NewOffice',
  getOffice = 'DataEntry/GetOffice',
  newFund = 'DataEntry/NewFund',
  getFund = 'DataEntry/GetFund',
}
