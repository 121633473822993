import './styles/global.style.css'
import axios from 'axios'
import ConfigProviders from 'components/ConfigProviders'
import { USER_JWT_TOKEN } from './constants'
import { useLogout } from 'hook'
import { useEffect } from 'react'
import { Routers } from 'routes'
import { authHeader } from 'services/authHeader'

axios.defaults.headers.common['Authorization'] = authHeader()

axios.interceptors.request.use(
  config => {
    config.baseURL = process.env.REACT_APP_BASE_URL_API
    return config
  },
  error => Promise.reject(error),
)

const App: React.FC = (): JSX.Element => {
  const { logoutHandler } = useLogout()
  axios.interceptors.response.use(
    response => response,
    error => {
      const { status } = error.response
      if (status === 401) logoutHandler()

      return Promise.reject(error)
    },
  )

  return (
    <ConfigProviders>
      <Routers />
    </ConfigProviders>
  )
}

export default App
