import { ROUTES } from 'models/enums'
import { NotFound } from 'pages/public/404/NotFound'
import ElectronicReceiptPage from 'pages/public/ElectronicReceipt'
import LoginPage from 'pages/public/Login'
import { FC } from 'react'
import { Navigate, RouteObject } from 'react-router-dom'
import LoginLayout from 'templates/loginLayout'
const Redirect: FC = () => {
  window.location.replace('https://e.czbank.com')
  return <div />
}
export const publicRoutes: RouteObject[] = [
  {
    path: ROUTES.electronicReceipt,
    element: <ElectronicReceiptPage />,
  },
  {
    path: ROUTES.login,
    element: <LoginLayout />,
    children: [
      {
        path: ROUTES.login,
        element: <LoginPage />,
      },
    ],
  },

  { path: '/', element: <Redirect /> },
  { path: '*', element: <NotFound /> },
]
