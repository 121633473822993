import { FC, ReactNode } from 'react'
import { LoginLayoutStyled } from './styles'
import { Outlet } from 'react-router-dom'

const LoginLayout: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <LoginLayoutStyled>
      <Outlet />
    </LoginLayoutStyled>
  )
}

export default LoginLayout
