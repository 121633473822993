import { Col, Row } from 'antd'
import { ReportCtxProvider } from './context/report'
import ReportsFilters from './components/Filters'
import ReportsTable from './components/Table'

const Reports = () => {
  return (
    <ReportCtxProvider>
      <Row gutter={[24, 24]} style={{ overflow: 'hidden' }}>
        {/* <Col span={24}>
          <ReportsFilters />
        </Col> */}
        <Col span={24} style={{ marginLeft: 8 }}>
          <ReportsTable />
        </Col>
      </Row>
    </ReportCtxProvider>
  )
}

export default Reports
