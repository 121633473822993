import { Space } from 'antd'
import styled from 'styled-components'

export const WrapperLogin = styled(Space)`
  padding: 32px;
  gap: 24px;
  max-width: 390px;
  width: 100%;
  background: #23272e;
  box-shadow: 0px 8px 60px rgba(0, 0, 0, 0.5);
  border-radius: 16px;

  .material-icons {
    color: #bfbfbf;
  }

  input::placeholder {
    color: #bfbfbf;
  }

  .companyName {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
  }

  .title {
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    margin-bottom: 0;
    text-align: left;
    color: ${props => props.theme.colors.text.primary};
    .turnosadra {
      color: #26a69a;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
    }
  }
  .description {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: justify;
    color: ${props => props.theme.colors.text.gray};
  }

  .loginBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px 12px;
    width: 100%;
    min-height: 45px;
    background: rgba(38, 166, 154, 0.7);
    border-radius: 8px;
  }
`
