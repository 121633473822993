import { Row } from 'antd'
import styled from 'styled-components'

export const PrintReportContainer = styled(Row)<{ accountnameheightneedtochange: string }>`
  width: 1400px;
  height: 668px;
  .normalFont {
    font-size: 20px;
    font-weight: 100;
  }
  .header {
    display: flex;
    align-items: end;
    justify-content: end;
    img {
      width: 965.5px;
      height: 98.36px;
      transform: translate(-1px, 2px);
      z-index: -10;
    }
    /* margin-top: 24px; */
    padding-bottom: 7px;
    .printHeaderFont {
      font-weight: 900;
      font-size: 44px;
      transform: translateX(124px);
    }

    .printLogo {
      width: 160px;
      transform: translateY(4px);
    }
    .logoContainer {
      transform: translateX(244px);
    }
    h1,
    p {
      margin-bottom: unset;
    }
    .randomNum {
      position: absolute;
      font-size: 21px;
      transform: translateY(-4.5px);
    }
  }

  .content {
    border: 1.3px solid #000;
    /* border-top: unset; */
    border-top-width: 2.5px;
    border-bottom-width: 1.7px;
    /* .payer {
      font-size: 26px;
      padding: 29px 29px 9px 29px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #000;
      border-bottom: unset;
      border-top: unset;

      font-weight: 900 !important;
      text-shadow: 0px 0px 0.3px black;
    }
    .payee {
      font-size: 26px;
      padding: 29px 29px 9px 29px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #000;
      border-top: unset;
      border-bottom: unset;
      font-weight: 100;
    } */
    .payer-detail {
      font-size: 26px;
      display: flex;
      justify-content: start;
      align-items: start;
      border-top: 1px solid #000;
      font-weight: 900 !important;
      transform: scaleY(1.1);
      padding: 6px 0px 5px 5px;
      /* text-shadow: 0px 0px 0.3px black; */
    }
    .payee-detail {
      font-size: 26px;
      display: flex;
      justify-content: start;
      border-top: 1px solid #000;
      border-right: 1px solid #000;
      padding-left: 10px;
      font-weight: 300;
      padding: 5px 0px 5px 5px;
      padding-top: ${({ accountnameheightneedtochange }) => (accountnameheightneedtochange === 'true' ? '6.3px' : '6px')};

      /* display: inline-block;
  font-size: 32px; */
      transform: scaleY(1.1);
    }
    .description {
      /* text-indent: 40px; */
      font-size: 23px;
      font-weight: 100;
      padding: 8px 0 0 0px;
      margin-bottom: 1px;
      img {
        width: 856px;
        height: 84px;
      }
    }
    .qrCode {
      z-index: 100;
      background-color: transparent;
      margin-bottom: 15px;
      position: relative;
      right: -7px;
      bottom: 2px;
      width: 155px !important;
      height: 165px !important;
      canvas {
        width: 124px !important;
        height: 140px !important;
      }
    }
    .qrCodeWrapper {
      position: absolute;
      right: 42.5px;
      bottom: 0;
      img {
        width: 259px;
        height: 237px;
      }
    }
    .amount {
      width: 134.8px;
      border: 1px solid #000;
      font-size: 28px;
      text-align: center;
      padding: 14px 0px 9px 0px;
      font-weight: 100;
      border-top-width: 1.5px;
    }
    .amount-detail {
      width: 100%;
      border: 1px solid #000;
      border-left: unset;
      font-size: 30px;
      padding: 0px 2px 8px 4px;
      font-weight: 100;
      display: flex;
      border-top-width: 1.5px;
      align-items: end;
      justify-content: space-between;
      /* text-shadow: 0px 0px -0.3px black; */
      img {
        height: 31px;
      }
    }
    .amountCost {
      transform: scaleY(1.1);
      font-size: 28px;

      /* &::after {
        content: attr(data-value);
        font-size: 30.2px;
        color: white;
      } */
    }
    .list {
      border: 1px solid #000;
      border-top: unset;
    }
    .list-container {
      width: 85%;
      padding: 16px 0 0 2px;
      ul {
        list-style: none;
        padding: unset;
        min-height: 202px;
        margin: unset;
      }
      li {
        font-size: 24px;
        font-weight: 100;
        display: flex;
        transform: translateX(-6px);

        span {
          margin-left: 14px;
          transform: scaleY(1.1);
          font-weight: 300;
          line-height: 24px;
        }
        img {
          height: 26px;
          transform: scale(0.9) translateY(-1px);
        }
      }
    }
    .bgLogo {
      position: absolute;
      left: -117px;
      top: 6px;
      z-index: -1;
      img {
        width: 250px;
        height: 199px;
      }
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    padding: 8px 0 8px 0;
    margin-left: 6px;
    font-size: 22px;
    font-weight: 100;
    p {
      margin: unset;
    }
    img {
      width: 1240px;
      height: 72px;
      transform: translateY(-2px);
    }
  }
  /* .dashed {
    border-bottom: 1px dashed #000; 
    width: 100%;
    transform: translateX(20px);
    background-image: url('/assets/images/dashed.png'); 
    background-repeat: repeat-x; 
    background-position: start; 
    height: 1.5px;
    background-size: 8px;
  } */
  .verticalTextHolder {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding-left: 2.5px;
    img {
      width: 28px;
      height: 264px;
    }
  }

  .verticalText {
    width: 0px;
    word-break: break-all;
    white-space: pre-wrap;
  }

  .payer-1 {
    img {
      width: 50px;
      height: 115px;
    }
    margin-top: -2px;
    border: 1px solid black;
    border-bottom: unset;
    padding: 6.5px 4px 1px 4px;
  }
  .payer-2 {
    img {
      /* width: 56px; */
      height: 27px;
      transform: translateY(2px);
      margin-bottom: 2px;
    }
  }
  .payer-3 {
    font-size: 26px !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    font-weight: 700;
    padding-left: 4px !important;
    align-items: end !important;
    img {
      width: 110px;
      /* transform: translateY(3px); */
    }
  }
  .amount {
    img {
      width: 109px;
      height: 28px;
    }
  }
  .payee-1 {
    img {
      width: 58px;
      height: 119px;
    }
    margin-top: -2px;
    border: 1px solid black;
    border-bottom: unset;
    padding: 3px 1px 1px 2px;
  }
  .payee-2 {
    align-items: end;

    img {
      width: 56px;
      height: 27px;
      transform: translateY(2px);
      margin-bottom: 2px;
    }
  }
  .payee-3 {
    font-size: 23px !important;
    padding-top: 6px !important;
    padding-bottom: 5px !important;

    img {
      width: 110px;
      /* transform: translateY(3px); */
    }
  }
`
export const PrintLoading = styled.div`
  overflow: hidden;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background: ${props => props.theme.colors.base};
  z-index: 1000000;
  width: 100vw;
  height: 100vh;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
`
