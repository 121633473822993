import { Button, Col, QRCode, Row, Spin } from 'antd'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { PrintLoading, PrintReportContainer } from './styles'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { IReportData } from 'services/controllers/User/models'
import { HomeService } from 'services/controllers/Home/Home.service'
import { ROUTES } from 'models/enums'
import { ECardsTitles } from 'modules/ElectronicReceipt'

export const PrintReport: FC = () => {
  const [params] = useSearchParams()
  const hashCode = params.get('hashCode')
  const accountNameRef = useRef<HTMLDivElement>(null)
  const [ElectronicReceiptData, setElectronicReceiptData] = useState<IReportData>()
  const [AccountNameHeight, setAccountNameHeight] = useState<number>()
  const location = useLocation()
  const navigate = useNavigate()
  const printRef = useRef<HTMLDivElement>(null)

  const formattedString = (inputString: string) => {
    const array = inputString.split('')
    const char = array.filter(char => /[a-zA-z]/.test(char)).join('')
    const finalFormat = char.concat(` ${inputString.replaceAll(char, '')}`)
    return finalFormat
  }

  const fetchData = useCallback(async () => {
    if (hashCode) {
      try {
        const { GetReceipt } = new HomeService()
        const result = await GetReceipt(hashCode, 'pdf')
        if (result && result.data) {
          setElectronicReceiptData(result.data)
        }
      } catch (err) {
        console.log(err)
      }
    }
  }, [hashCode])
  useEffect(() => {
    window.document.title = '浙商银行 电子回单'
    fetchData().then(() => {
      if (accountNameRef.current) setAccountNameHeight(accountNameRef.current.offsetHeight)
    })
  }, [fetchData])
  useEffect(() => {
    if (accountNameRef.current && ElectronicReceiptData) setAccountNameHeight(accountNameRef.current.offsetHeight)
  }, [ElectronicReceiptData, accountNameRef.current])
  const printDocument = () => {
    const input = document.getElementById('divToPrint')
    if (!input) return
    html2canvas(input).then(canvas => {
      const imgData = canvas.toDataURL('image/png')
      const pdf = new jsPDF()

      pdf.addImage(imgData, 'PNG', 0, 0, 210, 89.1, undefined, 'FAST')
      // pdf.output('dataurlnewwindow');
      pdf.save(`${formattedString(ElectronicReceiptData?.amount || 'download')}.pdf`)

      let navigateState = { pdfPrint: 'success', pagination: undefined }
      if (location.state.pagination) {
        navigateState.pagination = location.state.pagination
      }
      navigate(ROUTES.panel.concat('/' + ROUTES.reports), { state: navigateState })
    })
  }
  useEffect(() => {
    if (ElectronicReceiptData) {
      var print = setTimeout(() => printDocument(), 5000)
    }
    var timeOut = setTimeout(() => {
      let navigateState = { pdfPrint: 'failed', pagination: undefined }
      if (location.state?.pagination) {
        navigateState.pagination = location.state.pagination
      }
      navigate(ROUTES.panel.concat('/' + ROUTES.reports), { state: navigateState })
    }, 15000)
    return () => {
      clearTimeout(timeOut)
      clearTimeout(print)
    }
  }, [ElectronicReceiptData])
  const ftCode = useMemo(() => {
    const ten = ElectronicReceiptData?.trading_time.replaceAll('-', '').replaceAll(' ', '').replaceAll(':', '').substring(2)
    const finalCode = ten?.concat(ten[3]).concat(ten[6]).concat(ten[8].concat(ten[5]).concat(ten[7]))
    return finalCode
  }, [ElectronicReceiptData])
  const codeKey = 'ASjbgytasx%$54w4XS7fvgashiy^S***&shgvaskyassbyklljasggnjmjasuyjnhiuSLASIUASUHiusXUI7'
  return (
    <>
      <PrintReportContainer
        id={'divToPrint'}
        ref={printRef}
        className="printBodyFont"
        justify={'end'}
        accountnameheightneedtochange={(!!AccountNameHeight && AccountNameHeight > 42 && !!ElectronicReceiptData).toString()}
      >
        <Col span={21} style={{ paddingLeft: 1, paddingRight: 2 }}>
          <Row>
            <Col span={24} className="header">
              <img src="/assets/images/ECZImages/fullHeader2.png" />

              {/* <div className="logoContainer">
                <img src={'/assets/images/printLogo.png'} className='printLogo'/>
              </div>
              <h1 className="printHeaderFont ">
                客户电子回单<small className="ml-5"></small>(借)
                <span className=" printHeaderFont">(借)</span>
              </h1> */}
              <p className="randomNum">{ElectronicReceiptData?.randomString}</p>
            </Col>
          </Row>
          <Row className="content">
            <Col span={12}>
              <div className="flex">
                {/* <strong className="verticalTextHolder w-0 payer">付 款 人</strong>
                 */}
                <div className="payer-1">
                  <img src="/assets/images/ECZImages/1.png" />
                </div>
                <div className="w-full">
                  <div className="payer-detail payer-2 bold">
                    <img src="/assets/images/ECZImages/2.png" />
                    {ElectronicReceiptData?.payment_account_name}
                  </div>
                  <div className="payer-detail payer-2 bold" style={{ paddingLeft: 3 }}>
                    <img src="/assets/images/ECZImages/3.png" />
                    {ElectronicReceiptData?.payment_account_number}
                  </div>
                  <div className="payer-detail  payer-3 ">
                    <img src="/assets/images/ECZImages/4.png" />
                    <div>{ElectronicReceiptData?.bank_Name}</div>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="flex ">
                {/* <strong className="verticalTextHolder w-0 payee"> 收 款 人</strong> */}
                <div className="payee-1">
                  <img src="/assets/images/ECZImages/5.png" />
                </div>

                <div className="w-full">
                  <div
                    ref={accountNameRef}
                    className={` payee-2 payee-detail ${AccountNameHeight && AccountNameHeight > 42 && '!text-[24px]'}`}
                  >
                    <img src="/assets/images/ECZImages/6.png" />
                    <span> {ElectronicReceiptData?.recipient_account_name}</span>
                  </div>
                  <div className="payee-detail payee-2">
                    <img src="/assets/images/ECZImages/7.png" />
                    {ElectronicReceiptData?.receiving_account_number}
                  </div>
                  <div className="payee-detail payee-3">
                    <img src="/assets/images/ECZImages/8.png" />
                    {ElectronicReceiptData?.name_of_receiving_bank}
                  </div>
                </div>
              </div>
            </Col>
            <Col span={24}>
              <div className="flex">
                <div className="amount normalFont">
                  <img src="/assets/images/ECZImages/9.png" />
                </div>
                <div className=" amount-detail">
                  <div style={{ display: 'flex', alignItems: 'end' }}>
                    {ElectronicReceiptData?.chinaNumber}
                    {/* <img src="/assets/images/ECZImages/10.png" /> */}
                  </div>
                  <div className="amountCost">{ElectronicReceiptData?.amount}</div>
                </div>
              </div>
            </Col>
            <Col span={24} className="list">
              <Row>
                <Col span={17} className="list-container">
                  <div className="flex">
                    <div className="w-[49%]">
                      <ul>
                        <li>
                          <img src="/assets/images/ECZImages/11.png" style={{ width: 130 }} />
                          <span>{ElectronicReceiptData?.transaction_serial_number}</span>
                        </li>
                        <li>
                          <img src="/assets/images/ECZImages/12.png" /> <span>{ElectronicReceiptData?.business_ID}</span>
                          {/* 受理编号 : <span>FB2305160096456</span> */}
                        </li>
                        <li>
                          <img src="/assets/images/ECZImages/13.png" />
                          <span style={{ marginLeft: 0, transform: 'translateX(-6px)' }}>wy888</span>
                          {/* 交易时间 :<span>{ElectronicReceiptData?.trading_time}</span> */}
                        </li>
                        <li>
                          {/* 主机流水号 :<span>30873325</span> */}
                          <img src="/assets/images/ECZImages/14.png" />
                          <span>{ElectronicReceiptData?.trading_time}</span>
                        </li>
                        {/* <li>
                          经办网点 :<span>290000001</span>
                        </li>
                        <li>
                          手续费 :<span>0.00</span>
                        </li>
                        <li>
                          实际汇款金额 :<span>245188.00</span>
                        </li> */}
                      </ul>
                    </div>
                    <div className="w-[50%]">
                      <ul>
                        <li>
                          <img src="/assets/images/ECZImages/15.png" />
                          <span>{ElectronicReceiptData?.business_type}</span>
                        </li>
                        {/* <li>
                          凭证种类 :<span>0</span>
                        </li>
                        <li>
                          凭证号码 :<span></span>
                        </li>
                        <li>
                          经办柜员 :<span>wy888</span>
                        </li> */}
                        <li>
                          <img src="/assets/images/ECZImages/16.png" height={27} />
                          {/* <span style={{ marginLeft: 0 }}>FT2305160096456</span> */}
                          <span style={{ marginLeft: 0, transform: 'translateX(-7px)' }}>
                            FT
                            {ftCode}
                          </span>
                        </li>
                        {/* <li>
                          邮电费 :<span>0.00</span>
                        </li> */}
                        <li>
                          {/* 摘要 :<span></span> */}
                          <img src="/assets/images/ECZImages/17.png" />
                          <span>{ElectronicReceiptData?.initiator}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="description ">
                    <img src="/assets/images/ECZImages/description.png" />
                  </div>
                </Col>
                <Col span={7} className="flex justify-center items-end">
                  <div className="qrCodeContainer">
                    <div>
                      <QRCode
                        bordered={false}
                        className="qrCode"
                        value={
                          process.env.REACT_APP_CLIENT_URL?.concat(
                            ROUTES.electronicReceipt.concat(
                              `?user=${ElectronicReceiptData?.id}&hashCode=${ElectronicReceiptData?.uniqString}&KEY={${codeKey}}`,
                            ),
                          ) || ''
                        }
                      />
                    </div>
                    <div className="qrCodeWrapper">
                      <img src="/assets/images/qrCodeWrapper.png" alt="..." />
                    </div>
                  </div>
                  <div className="bgLogo">
                    <img src="/assets/images/ECZImages/background.png" alt="..." />
                  </div>
                </Col>
              </Row>
              {/* <div className="flex flex-col"> */}
              {/* <div className="list-container">
                <div className="flex">
                  <div className="w-[50%]">
                    <ul>
                      <li>
                        交易流水号 : <span>30873360120230516</span>{' '}
                      </li>
                      <li>
                        受理编号 : <span>FB2305160096456</span>
                      </li>
                      <li>
                        交易时间 :<span>2023-05-16 15:01</span>
                      </li>
                      <li>
                        主机流水号 :<span>30873325</span>
                      </li>
                      <li>
                        经办网点 :<span>290000001</span>
                      </li>
                      <li>
                        手续费 :<span>0.00</span>
                      </li>
                      <li>
                        实际汇款金额 :<span>245188.00</span>
                      </li>
                    </ul>
                  </div>
                  <div className="w-[50%]">
                    <ul>
                      <li>
                        业务种类 :<span>外汇批量汇款</span>
                      </li>
                      <li>
                        凭证种类 :<span>0</span>
                      </li>
                      <li>
                        凭证号码 :<span></span>
                      </li>
                      <li>
                        经办柜员 :<span>wy888</span>
                      </li>
                      <li>
                        批次号 :<span>FB2305160096456</span>
                      </li>
                      <li>
                        邮电费 :<span>0.00</span>
                      </li>
                      <li>
                        摘要 :<span></span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}

              {/* <div className="description ">
                本回单仅作付款人付款回执，不作收款人收款入账回执。收款请以收款银行实
                际入账为准。本回单需加盖浙商银行电子签名章，并可通过二维码识别软件读取电
                子签名或通过本行网站(www.czbank.com)和全国统一服务电话(95527)使用防伪码查询
              </div>
              <div className="bgLogo">
                <img src="/assets/images/printLogo.png" alt="..." width={231} />
              </div>
            </div> */}
              {/* <div>
              <img src="/assets/images/qrCodeWrapper.png" alt="..." width={231} />
            </div> */}
            </Col>
          </Row>
          <Row>
            <Col span={24} className="footer">
              {/* <p>第2次打印(多次打印的请避免重复)</p> */}
              {/* <img src="/assets/images/pdfFooter.png" /> */}
              {/* <p>打印申请人:002</p> */}
              <img src="/assets/images/ECZImages/footer.png" />
            </Col>
            {/* <div className="dashed" /> */}
          </Row>
        </Col>
        <Col span={1} className="verticalTextHolder normalFont pt-5">
          {/* <p className="verticalText">本 凭 证 作 客 户 付 款 通 知</p> */}
          <img src="/assets/images/ECZImages/vertical.png" />
        </Col>
      </PrintReportContainer>
      <Button onClick={() => printDocument()}>Download</Button>
      <PrintLoading className="loading">
        <h1>please wait...</h1>
        <Spin spinning={true} />
      </PrintLoading>
    </>
  )
}
