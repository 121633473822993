import { Row } from 'antd'
import styled from 'styled-components'

export const TabsUiKitContainer = styled(Row)`
  padding: 0 8px;
  position: relative;

  .output {
    position: absolute;
    left: 20px;
    bottom: 20px;

    .output-tooltip {
      margin-left: 15px;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 481px) {
    .output {
      left: 10px;

      .output-tooltip {
        margin-left: 10px;
      }
    }
  }

  @media only screen and (max-width: 596px) {
    padding-bottom: 24px;
    .ant-tabs-nav-list {
      padding-right: 10px;
    }
  }
`
