import styled from 'styled-components'

export const LoadingStyle = styled.div`
  overflow: hidden;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background: ${props => props.theme.colors.base};
  z-index: 1000000;
  width: 100vw;
  height: 100vh;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
`
