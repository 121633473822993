import { apiCaller } from 'services/apiCaller'
import { DataEntryUrls } from './urls'
import {
  IArgsNewBank,
  IArgsNewBankAccount,
  IArgsNewOffice,
  IArgsNewCountry,
  IArgsNewCurrency,
  IArgsNewCustomer,
  ICountries,
  IDataEntry,
  IArgsNewCash,
  IArgsGetBankAccount,
  IArgsGetCash,
  IArgsNewFund,
  IArgsGetOffice,
  IArgsGetFund,
  IArgsGetBank,
  IArgsNewOwner,
} from './models'

export class DataEntryService {
  async NewCountry(data: IArgsNewCountry) {
    try {
      const response = await apiCaller.post(DataEntryUrls.newCountry, data)
      return response
    } catch (err) {
      console.log(err)
    }
  }
  async GetCustomer() {
    try {
      const response = await apiCaller.get<IDataEntry[]>(DataEntryUrls.getCustomer)
      return response
    } catch (err) {
      console.log(err)
    }
  }
  async NewCash(data: IArgsNewCash) {
    try {
      const response = await apiCaller.post(DataEntryUrls.newCash, data)
      return response
    } catch (err) {
      console.log(err)
    }
  }
  async GetCashes(params?: IArgsGetCash) {
    try {
      const response = await apiCaller.get<IDataEntry[]>(DataEntryUrls.getCashes, {
        params: params,
      })
      return response
    } catch (err) {
      console.log(err)
    }
  }

  async GetCountry() {
    try {
      const response = await apiCaller.get<ICountries[]>(DataEntryUrls.getCountry)
      return response
    } catch (err) {
      console.log(err)
    }
  }

  async NewCustomer(data: IArgsNewCustomer) {
    try {
      const response = await apiCaller.post(DataEntryUrls.newCustomer, data)
      return response
    } catch (err) {
      console.log(err)
    }
  }
  async NewOwner(data: IArgsNewOwner) {
    try {
      const response = await apiCaller.post(DataEntryUrls.newOwner, data)
      return response
    } catch (err) {
      console.log(err)
    }
  }
  async NewBank(data: IArgsNewBank) {
    try {
      const response = await apiCaller.post(DataEntryUrls.newBank, data)
      return response
    } catch (err) {
      console.log(err)
    }
  }
  async NewCurrency(data: IArgsNewCurrency) {
    try {
      const response = await apiCaller.post(DataEntryUrls.newCurrency, data)
      return response
    } catch (err) {
      console.log(err)
    }
  }
  async GetCurrency() {
    try {
      const response = await apiCaller.get<IDataEntry[]>(DataEntryUrls.getCurrency)
      return response
    } catch (err) {
      console.log(err)
    }
  }

  async GetOwner() {
    try {
      const response = await apiCaller.get<IDataEntry[]>(DataEntryUrls.getOwner)
      return response
    } catch (err) {
      console.log(err)
    }
  }
  async GetBank(params?: IArgsGetBank) {
    try {
      const response = await apiCaller.get<IDataEntry[]>(DataEntryUrls.getBank, { params: params })
      return response
    } catch (err) {
      console.log(err)
    }
  }
  async GetBankAccount(params?: IArgsGetBankAccount) {
    console.log(params)

    try {
      const response = await apiCaller.get<IDataEntry[]>(DataEntryUrls.getBankAccount, {
        params: params,
      })
      return response
    } catch (err) {
      console.log(err)
    }
  }
  async NewBankAccount(data: IArgsNewBankAccount) {
    try {
      const response = await apiCaller.post<void>(DataEntryUrls.newBankAccount, data)
      return response
    } catch (err) {
      console.log(err)
    }
  }

  async NewOffice(data: IArgsNewOffice) {
    try {
      const response = await apiCaller.post(DataEntryUrls.newOffice, data)
      return response
    } catch (err) {
      console.log(err)
    }
  }
  async GetOffice(params?: IArgsGetOffice) {
    try {
      const response = await apiCaller.get<IDataEntry[]>(DataEntryUrls.getOffice, { params: params })
      return response
    } catch (err) {
      console.log(err)
    }
  }

  async NewFund(data: IArgsNewFund) {
    try {
      const response = await apiCaller.post(DataEntryUrls.newFund, data)
      return response
    } catch (err) {
      console.log(err)
    }
  }
  async GetFund(params?: IArgsGetFund) {
    try {
      const response = await apiCaller.get<IDataEntry[]>(DataEntryUrls.getFund, { params: params })
      return response
    } catch (err) {
      console.log(err)
    }
  }
}
