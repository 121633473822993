import { FC, useEffect, useRef, useState } from 'react'
import TableUi from 'components/Ui/table'
import { ColumnsType } from 'antd/lib/table/InternalTable'
import { useReportCtx } from '../context/report'
import { ReportTableWrapper } from '../styles'
import { ROUTES } from 'models/enums'
import ExcelPdfOutput from 'components/ExcelPdfOutput'
import { ECardsEnTitles, ECardsTitles } from 'modules/ElectronicReceipt'
import { Modal, Popover, QRCode, Spin, Table, Tooltip, message } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ButtonUiKit from 'components/Ui/Button'
import DarkTheme from 'styles/theme/DarkTheme'
import { IReportData } from 'services/controllers/User/models'

const ReportsTable: FC = () => {
  const tableRef = useRef(null)
  const [currentHashCode, setCurrentHashCode] = useState<string>('')
  const [showModal, setShowModal] = useState<boolean>(false)
  const {
    states: { reports, params, loading },
    dispatches: { setParams },
  } = useReportCtx()
  const [messageApi, contextHolder] = message.useMessage()
  const location = useLocation()

  useEffect(() => {
    console.log(location.state?.pdfPrint)
    if (location.state) {
      if (location.state.pdfPrint) {
        if (location.state.pdfPrint === 'success') {
          message.success('Download PDF is Done')
          location.state.pdfPrint = undefined
        }
        if (location.state.pdfPrint === 'failed') {
          message.error('Failed to Download PDF')
        }
        if (location.state.pagination) {
          setParams(perv => ({ ...perv, Offset: location.state.pagination.current }))
        }
      }
    }
  }, [location])

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Copied.',
      style: {
        color: DarkTheme.colors.base,
      },
    })
  }

  const error = () => {
    messageApi.open({
      type: 'error',
      content: 'Copy is failed.',
      style: {
        color: DarkTheme.colors.base,
      },
    })
  }

  const PopoverHandler = (text: string, length: number = 21) => {
    if (text.length > length) {
      const textSubstring = text.substring(0, length).concat('...')
      return (
        <Popover color="geekblue" content={text}>
          {textSubstring}
        </Popover>
      )
    } else return text
  }

  const columns: ColumnsType<IReportData> = [
    {
      title: PopoverHandler(ECardsEnTitles.transaction_serial_number),
      dataIndex: 'transaction_serial_number',
      key: '1',
      width: '200px',
    },
    {
      title: PopoverHandler(ECardsEnTitles.payment_account_name),
      dataIndex: 'payment_account_name',
      key: '2',
      width: '200px',
    },
    {
      title: PopoverHandler(ECardsEnTitles.payment_account_number),
      dataIndex: 'payment_account_number',
      key: '3',
      width: '200px',
    },
    {
      title: PopoverHandler(ECardsEnTitles.bank_Name),
      dataIndex: 'bank_Name',
      key: '4',
      width: '200px',
    },
    {
      title: PopoverHandler(ECardsEnTitles.recipient_account_name),
      dataIndex: 'recipient_account_name',
      key: '5',
      width: '200px',
    },
    {
      title: PopoverHandler(ECardsEnTitles.receiving_account_number),
      dataIndex: 'receiving_account_number',
      key: '6',
      width: '200px',
    },
    {
      title: PopoverHandler(ECardsEnTitles.name_of_receiving_bank),
      dataIndex: 'name_of_receiving_bank',
      key: '7',
      width: '200px',
    },
    {
      title: PopoverHandler(ECardsEnTitles.amount),
      dataIndex: 'amount',
      key: '8',
      width: '200px',
    },
    {
      title: PopoverHandler(ECardsEnTitles.currency),
      dataIndex: 'currency',
      key: '9',
      width: '200px',
    },
    {
      title: PopoverHandler(ECardsEnTitles.business_type),
      dataIndex: 'business_type',
      key: '10',
      width: '200px',
    },
    {
      title: PopoverHandler(ECardsEnTitles.business_ID),
      dataIndex: 'business_ID',
      key: '11',
      width: '200px',
    },
    {
      title: PopoverHandler(ECardsEnTitles.initiator),
      dataIndex: 'initiator',
      key: '12',
      width: '200px',
    },
    {
      title: PopoverHandler(ECardsEnTitles.trading_time),
      dataIndex: 'trading_time',
      key: '13',
      width: '200px',
    },

    {
      title: 'Actions',
      dataIndex: 'action',
      key: '14',
      fixed: 'right',
      width: '200px',
      render: (value, record, index) => {
        const url =
          process.env.REACT_APP_CLIENT_URL?.concat(ROUTES.electronicReceipt.concat(`?user=${record.id}&hashCode=${record.uniqString}`)) ||
          ''

        return (
          <div
            style={{
              display: 'flex',
              gap: 16,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {
              <CopyToClipboard
                text={url}
                onCopy={(text, result) => {
                  if (result) success()
                  else error()
                }}
              >
                <Tooltip title="Copy" color="purple">
                  <div>
                    <span className="material-icons" style={{ cursor: 'pointer' }}>
                      content_copy
                    </span>
                  </div>
                </Tooltip>
              </CopyToClipboard>
            }
            <Tooltip title="QR Code" color="purple">
              <span
                className="material-icons"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setCurrentHashCode(url)
                  setShowModal(true)
                }}
              >
                qr_code_2
              </span>
            </Tooltip>
            <Tooltip title="Open in browser" color="purple">
              <Link target="_blank" to={url} style={{ color: 'inherit' }}>
                <span className="material-icons" style={{ cursor: 'pointer' }}>
                  travel_explore
                </span>
              </Link>
            </Tooltip>
            <Tooltip title="Print" color="purple">
              <Link
                // target="_blank"
                to={ROUTES.printReport.concat(`?hashCode=${record.uniqString}`)}
                style={{ color: 'inherit' }}
                state={{ pagination: { current: params.Offset } }}
                // onClick={() => {
                //   navigate(ROUTES.printReport.concat(`?hashCode=${record.uniqString}&url=${url}`))
                // }}
              >
                <span className="material-icons" style={{ cursor: 'pointer' }}>
                  print
                </span>
              </Link>
            </Tooltip>
          </div>
        )
      },
    },
  ]
  const downloadQRCode = () => {
    const canvas = document.getElementById('myqrcode')?.querySelector<HTMLCanvasElement>('canvas')
    if (canvas) {
      const url = canvas.toDataURL()
      const a = document.createElement('a')
      a.download = 'QRCode.png'
      a.href = url
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  }
  return (
    <ReportTableWrapper ref={tableRef}>
      {contextHolder}
      <div style={{ width: 'calc(100vw - 32px)' }}>
        <TableUi
          rowKey={'id'}
          scroll={{ x: 1300 }}
          dataSource={reports?.records}
          columns={columns}
          loading={loading}
          pagination={{
            position: ['bottomLeft'],
            pageSize: params.Limit,
            total: reports?.count,
            current: params.Offset,
            showSizeChanger: true,

            onChange(page, pageSize) {
              setParams(prev => ({ ...prev, Limit: pageSize, Offset: page }))
            },
          }}
        />
      </div>

      <Modal
        open={showModal}
        onCancel={() => {
          setShowModal(false)
          setCurrentHashCode('')
        }}
        footer={null}
      >
        <Spin spinning={!currentHashCode}>
          <div id="myqrcode" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <QRCode value={currentHashCode} style={{ marginBottom: 16 }} />
            <ButtonUiKit type="primary" onClick={downloadQRCode}>
              Download
            </ButtonUiKit>
          </div>
        </Spin>
      </Modal>
      {reports?.records?.length && <ExcelPdfOutput tableRef={tableRef} />}
    </ReportTableWrapper>
  )
}

export default ReportsTable
