import { PaginationData } from 'models/enums'
import { createContext, FC, ReactElement, useContext, useState } from 'react'
import { ArgsGetReport } from 'services/controllers/reports/models'
import { IDataModel, IPaginationParams } from 'services/model'
import { useGetReceipt } from '../hook/useGetReceipt'
import { IReportData } from 'services/controllers/User/models'

interface IContextValue {
  states: {
    params: IPaginationParams
    reports?: IDataModel<IReportData[]>
    loading?: boolean
  }
  dispatches: {
    setParams: React.Dispatch<React.SetStateAction<Partial<ArgsGetReport>>>
  }
}

const ReportCtx = createContext<IContextValue | undefined>(undefined)

export const ReportCtxProvider: FC<{ children: ReactElement | ReactElement[] }> = ({ children }) => {
  const [params, setParams] = useState<ArgsGetReport>({
    Limit: PaginationData.Limit,
    Offset: 1,
    IsDece: true,
  })

  const { reports, loadingReports } = useGetReceipt({ ...params })

  const contextValue: IContextValue = {
    states: {
      loading: loadingReports,
      reports,
      params,
    },
    dispatches: {
      setParams,
    },
  }
  return <ReportCtx.Provider value={contextValue}>{children}</ReportCtx.Provider>
}

export const useReportCtx = () => useContext(ReportCtx)!
