import { Col, Form, Row } from 'antd'
import Validator from 'validator'
import { useState } from 'react'
import { WrapperLogin } from './styles'
import LoginLogo from './components/Logo'
import LoginTitle from './components/Title'
import FormUiKit from 'components/Ui/Form'
import InputUiKit from 'components/Ui/Input'
import InputPassword from 'components/Ui/inputPassword'
import ButtonUiKit from 'components/Ui/Button'
import { useLogin } from 'hook/auth/useLogin'
import { ILoginArgs } from 'services/controllers/Home/models'
import { HomeService } from 'services/controllers/Home/Home.service'

const Login = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const { loginHandler } = useLogin()
  const { Login } = new HomeService()

  const loginRequest = async (params: ILoginArgs) => {
    setLoading(true)
    try {
      const res = await Login(params)
      res?.data.token && loginHandler(res?.data.token)
    } finally {
      setLoading(false)
    }
  }

  const onLoginHandler = (values: { emailOrPhone: string; password: string }) => {
    loginRequest({
      password: values.password,
      email: Validator.isMobilePhone(values.emailOrPhone) ? values.emailOrPhone.substring(1) : values.emailOrPhone,
    })
  }

  return (
    <WrapperLogin direction="vertical" size={24}>
      <LoginLogo />
      <LoginTitle />

      <FormUiKit onFinish={onLoginHandler} className="mt-5">
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Form.Item
              required={false}
              name={'emailOrPhone'}
              rules={[
                {
                  required: true,
                  message: 'Email is required',
                },
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.resolve()
                    }
                    if (Validator.isEmail(value) || Validator.isMobilePhone(value)) {
                      return Promise.resolve()
                    } else {
                      return Promise.reject(new Error('Email is not valid'))
                    }
                  },
                }),
              ]}
            >
              <InputUiKit icon={<span className="material-icons">email</span>} placeholder="Email" className="phoneNumberInput" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name={'password'} required={false} rules={[{ required: true, message: 'Password is required' }]}>
              <InputPassword allowClear placeholder="Password" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <ButtonUiKit loading={loading} type="primary" htmlType="submit" className="loginBtn mt-2">
              Login
            </ButtonUiKit>
          </Col>
        </Row>
      </FormUiKit>
    </WrapperLogin>
  )
}

export default Login
