import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
body{
   font-family: sans-serif !important;
}

body,h1,h2,h3,h4,h5,h6,div,p,strong,button,select,textarea,label,input,input::placeholder,span:not(.material-icons){
  font-family: sans-serif ;
}
span.material-icons-outlined{
  font-family: Material Icons Outlined !important;
}

.printBodyFont{
  font-family: CH !important;
  
  /* text-rendering: geometricPrecision; */
  & *{
    font-family: CH !important;
    /* text-rendering: geometricPrecision; */

  }
  /* .bold{
    font-family: GRBold !important;
  & *{
    font-family: GRBold !important;

  }
  } */
}
.printHeaderFont{
  font-family: headerPdf !important;
}
.chinesFont{
  font-family: CH !important;
  transform: scaleY(1.1);

}
input::placeholder{
font-family: sans-serif;
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 22px;
display: flex;
align-items: center;
text-align: left;
color: ${props => props.theme.colors.text.disabled};
}

input:-webkit-autofill{
  -webkit-text-fill-color:  ${props => props.theme.colors.disable}  !important;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: ${props => props.theme.scroll.scrollTrack};
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color:  ${props => props.theme.scroll.scrollHandle};
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color:  ${props => props.theme.scroll.scrollHover};
}

/* Handle */
::-webkit-scrollbar-thumb {
  background:  ${props => props.theme.scroll.scrollHandle};
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background:  ${props => props.theme.scroll.scrollHover};
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ant-picker-cell-in-range::before{
  background: #535857 !important;
}

input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: green;
    -webkit-box-shadow: 0 0 0 1000px ${props => props.theme.colors.secondary} inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  .swal2-cancel{
  width: 50% !important;
}
.swal2-confirm.swal2-styled {
  width: 255px;
    height: 52px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    background-color: ${props => props.theme.colors.primary} !important;
    border: 1px solid  ${props => props.theme.colors.primary} !important;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 4px;
}
.swal2-title  {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  
}
.swal2-html-container {
  color: ${props => props.theme.colors.text.gray} !important;
}
.swal2-icon {
  border: none !important;
}


//notification
.notificationDropdown{
  &::-webkit-scrollbar {
    display: none;
}

  .ant-spin{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55px;
  }
  box-shadow: 0px 1.41214px 7.06069px rgba(0, 0, 0, 0.38);
  width: 300px;
  max-height: 400px;
  position: relative;
  overflow-y: auto;
  border-radius: 4px;
  .ant-dropdown-menu{
    background: #23272E !important;
    padding: 0;
  }
  span{
    color: #fff;
  }

  .ant-empty-image{
    height: 70px;
  }

  .ant-dropdown-menu-item-divider{
    background: rgba(86, 87, 91, 0.3) !important;
  }

  .ant-dropdown-menu-item:last-child{
    position: sticky;
    left: 0;
    right: 0;
    bottom: 0;
    background: #23272E !important;
    box-shadow: 0px 1.41214px 7.06069px rgba(0, 0, 0, 0.38)
  }
}



.ant-divider{
  background: ${props => props.theme.colors.disable}30  !important;
}

.ant-tabs-dropdown-menu{
  background: ${props => props.theme.colors.secondary}  !important;
}

`
