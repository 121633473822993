import { Layout, Skeleton } from 'antd'
import { FC, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { IMainLayoutProps } from './interface'
import { MainLayoutContainer } from './styles'
import { ContentContainer } from './styles/content'
import Sidebar from './components/Sidebar'
import MainLayoutHeader from './components/header'
import Sider from 'antd/es/layout/Sider'

const { Content } = Layout
export const MainLayout: FC<IMainLayoutProps> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false)
  return (
    <Skeleton loading={false} active avatar>
      <MainLayoutContainer>
        <Layout>
          <Layout>
            <Sidebar open={open} setOpen={setOpen} />
            <MainLayoutHeader setOpen={setOpen} />
            <Content>
              <ContentContainer>
                <Outlet />
              </ContentContainer>
            </Content>
          </Layout>
        </Layout>
        {/* <Layout>
          <Sider>
            <Sidebar open={open} setOpen={setOpen} />
          </Sider>
          <Layout>
            <MainLayoutHeader />
            <Content>
              <ContentContainer>
                <Outlet />
              </ContentContainer>
            </Content>
          </Layout>
        </Layout> */}
      </MainLayoutContainer>
    </Skeleton>
  )
}
