import { USER_JWT_TOKEN } from 'constants/localStorage'
import useAuthStore from 'store/auth'

export const useLogout = () => {
  const { logout } = useAuthStore()

  const logoutHandler = async () => {
    localStorage.removeItem(USER_JWT_TOKEN)
    logout()
  }

  return { logoutHandler }
}
