export enum ROUTES {
  home = '/',
  login = '/login',
  panel = '/panel',
  reports = 'reports',
  changePassword = 'change-password',
  loginHistory = 'login-history',
  electronicReceipt = '/electronic-receipt',
  printReport = '/printReport',
}
