import { Button } from 'antd'
import styled from 'styled-components'
import { ButtonOtherType } from '..'

export const ButtonUiKitStyled = styled(Button)<{ othertype?: ButtonOtherType }>`
  padding: 0px 24px;
  display: flex;
  align-items: center;

  ${({ othertype, disabled }) => {
    if (othertype) {
      switch (othertype) {
        case 'secondary':
          return `
          background-color: #4A4B4F!important;
          ${
            disabled &&
            `
            border-color: #4A4B4F !important;
            color:#d3d3d370 !important;
          `
          }
          
          `
        default:
          return ''
      }
    }
  }}

  .material-icons {
    font-size: 15px;
    margin-left: 10px;
  }
`
