import styled from 'styled-components'

export const WrapperRegisterBank = styled.section`
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  gap: 24px;
  width: 100%;
  /* max-width: 732px; */
  background: ${props => props.theme.colors.secondary};
  box-shadow: 0px 8px 60px rgba(0, 0, 0, 0.5);
  border-radius: 14px;
  margin: 0 auto;

  .btn {
    padding: 12px 32px;
    width: 160px;
    height: 48px;
    justify-content: center;
    margin: auto;
    margin-top: 1rem;
  }

  /* .input {
    margin: auto;
    width: 326px;
  } */

  @media only screen and (max-width: 524px) {
    .input,
    .btn {
      width: 100%;
    }
    padding: 14px 16px;
  }
`

export const WrapperRegisterBankTable = styled.section`
  width: 100%;
  max-width: 1100px;
  background: ${props => props.theme.colors.secondary};
  box-shadow: 0px 8px 60px rgba(0, 0, 0, 0.5);
  border-radius: 14px;
  margin: 0 auto;
  margin-top: 40px;
  padding: 0 10px 17px 10px;
  text-align: center;

  .title-wrapper {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: baseline;
    align-items: center;
    padding: 34px 22px 10px 22px;
  }
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }
  .carousel-item-wrapper {
    width: 100%;
    height: 76px;
    display: inline-flex;
    align-items: center;
    background: ${props => props.theme.colors.base};
    margin: 0 auto;
    border-radius: 8px;
    overflow-x: auto;
    ::-webkit-scrollbar {
      display: none;
    }
  }

  .carousel-item {
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 0 40px; */
    min-width: 150px;
    height: 30px;
    color: ${props => props.theme.colors.text.disabled};
    border-left: solid 1px ${props => props.theme.colors.text.disabled};
  }
  .ant-btn:hover {
    background-color: transparent;
  }
  .buttons-wrapper {
    display: inline-flex;
    margin-top: 10px;
    .btn-east,
    .btn-west {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px;
      background-color: ${props => props.theme.colors.gray};
      opacity: 0.4;
      box-shadow: 0px 2px 60px rgba(0, 0, 0, 0.05);
      border-radius: 4px;

      span {
        color: ${props => props.theme.colors.text.primary};
      }
    }
  }
`
