import { FC } from 'react'
import { Col } from 'antd'
import { TabsUiKitContainer } from './styles/tab'

import RegisterBank from 'modules/RegisterBank'

const Panel: FC = () => {
  return (
    <TabsUiKitContainer align={'middle'} justify={'center'}>
      <Col xs={24} md={22}>
        <RegisterBank />
      </Col>
    </TabsUiKitContainer>
  )
}

export default Panel
