import { WrapperRegisterBank } from './styles'
import { Col, Divider, Form, Row, Typography } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import axios from 'axios'
import ButtonUiKit from 'components/Ui/Button'
import FormUiKit from 'components/Ui/Form'
import InputUiKit from 'components/Ui/Input'
import { Loading } from 'components/Ui/Loading'
import { SwalََAlert } from 'components/alert'
import { IElectronicReceiptData, ECardsEnTitles } from 'modules/ElectronicReceipt'
import { useEffect, useState } from 'react'
import { UserService } from 'services/controllers/User/User.service'

const RegisterBank = () => {
  const [FormControl] = useForm()
  const [loading, setLoading] = useState<boolean>(false)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const service = new UserService()

  const onFinish = async (values: IElectronicReceiptData) => {
    setLoading(true)
    try {
      const res = await service.CreateReceipt(values)
      if (res && res.status === 200)
        SwalََAlert({ type: 'success', text: 'Creation was successful', onOk: () => FormControl.resetFields() })
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }
  const fetchReport = async () => {
    service.GetReceipt({ Limit: 1, Offset: 1 }).finally(() => {
      setPageLoading(false)
    })
  }
  useEffect(() => {
    fetchReport()
  }, [])
  return (
    <FormUiKit onFinish={onFinish} form={FormControl}>
      {pageLoading && <Loading />}
      <WrapperRegisterBank>
        <Typography.Title level={5}> Add New </Typography.Title>
        <Divider className="!my-1" />
        <Row gutter={[16, 16]}>
          <Col xs={24} md={8}>
            <Form.Item
              name={'transaction_serial_number'}
              className="input"
              rules={[{ required: true, message: `${ECardsEnTitles.transaction_serial_number.replace(':', '')} is required.` }]}
            >
              <InputUiKit
                label={ECardsEnTitles.transaction_serial_number}
                placeholder={`Write a ${ECardsEnTitles.transaction_serial_number.replace(':', '')}`}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name={'payment_account_name'}
              className="input"
              rules={[{ required: true, message: `${ECardsEnTitles.payment_account_name.replace(':', '')} is required.` }]}
            >
              <InputUiKit
                label={ECardsEnTitles.payment_account_name}
                placeholder={`Write a ${ECardsEnTitles.payment_account_name.replace(':', '')}`}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name={'payment_account_number'}
              className="input"
              rules={[{ required: true, message: `${ECardsEnTitles.payment_account_number.replace(':', '')} is required.` }]}
            >
              <InputUiKit
                label={ECardsEnTitles.payment_account_number}
                placeholder={`Write a ${ECardsEnTitles.payment_account_number.replace(':', '')}`}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name={'bank_Name'}
              className="input"
              rules={[{ required: true, message: `${ECardsEnTitles.bank_Name.replace(':', '')} is required.` }]}
            >
              <InputUiKit label={ECardsEnTitles.bank_Name} placeholder={`Write a ${ECardsEnTitles.bank_Name.replace(':', '')}`} />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name={'recipient_account_name'}
              className="input"
              rules={[{ required: true, message: `${ECardsEnTitles.recipient_account_name.replace(':', '')} is required.` }]}
            >
              <InputUiKit
                label={ECardsEnTitles.recipient_account_name}
                placeholder={`Write a ${ECardsEnTitles.recipient_account_name.replace(':', '')}`}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name={'receiving_account_number'}
              className="input"
              rules={[{ required: true, message: `${ECardsEnTitles.receiving_account_number.replace(':', '')} is required.` }]}
            >
              <InputUiKit
                label={ECardsEnTitles.receiving_account_number}
                placeholder={`Write a ${ECardsEnTitles.receiving_account_number.replace(':', '')}`}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name={'name_of_receiving_bank'}
              className="input"
              rules={[{ required: true, message: `${ECardsEnTitles.name_of_receiving_bank.replace(':', '')} is required.` }]}
            >
              <InputUiKit
                label={ECardsEnTitles.name_of_receiving_bank}
                placeholder={`Write a ${ECardsEnTitles.name_of_receiving_bank.replace(':', '')}`}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name={'amount'}
              className="input"
              rules={[{ required: true, message: `${ECardsEnTitles.amount.replace(':', '')} is required.` }]}
            >
              <InputUiKit label={ECardsEnTitles.amount} placeholder={`Write a ${ECardsEnTitles.amount.replace(':', '')}`} />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name={'currency'}
              className="input"
              rules={[{ required: true, message: `${ECardsEnTitles.currency.replace(':', '')} is required.` }]}
            >
              <InputUiKit label={ECardsEnTitles.currency} placeholder={`Write a ${ECardsEnTitles.currency.replace(':', '')}`} />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name={'business_type'}
              className="input"
              rules={[{ required: true, message: `${ECardsEnTitles.business_type.replace(':', '')} is required.` }]}
            >
              <InputUiKit label={ECardsEnTitles.business_type} placeholder={`Write a ${ECardsEnTitles.business_type.replace(':', '')}`} />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name={'business_ID'}
              className="input"
              rules={[{ required: true, message: `${ECardsEnTitles.business_ID.replace(':', '')} is required.` }]}
            >
              <InputUiKit label={ECardsEnTitles.business_ID} placeholder={`Write a ${ECardsEnTitles.business_ID.replace(':', '')}`} />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name={'initiator'}
              className="input"
              rules={[{ required: true, message: `${ECardsEnTitles.initiator.replace(':', '')} is required.` }]}
            >
              <InputUiKit label={ECardsEnTitles.initiator} placeholder={`Write a ${ECardsEnTitles.initiator.replace(':', '')}`} />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name={'trading_time'}
              className="input"
              rules={[{ required: true, message: `${ECardsEnTitles.trading_time.replace(':', '')} is required.` }]}
            >
              <InputUiKit label={ECardsEnTitles.trading_time} placeholder={`Write a ${ECardsEnTitles.trading_time.replace(':', '')}`} />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name={'chinaNumber'}
              className="input"
              rules={[{ required: true, message: `${ECardsEnTitles.chinaNumber.replace(':', '')} is required.` }]}
            >
              <InputUiKit label={ECardsEnTitles.chinaNumber} placeholder={`Write a ${ECardsEnTitles.chinaNumber.replace(':', '')}`} />
            </Form.Item>
          </Col>
        </Row>
        <ButtonUiKit loading={loading} htmlType="submit" type="primary" className="btn">
          Create
        </ButtonUiKit>
      </WrapperRegisterBank>
    </FormUiKit>
  )
}

export default RegisterBank
