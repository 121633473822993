import { MenuProps } from 'antd'
import { ROUTES } from 'models/enums/routes'
import { Link } from 'react-router-dom'
import { getItem } from '../helper/createItem'
import { useLogout } from 'hook'
import { Dispatch, SetStateAction } from 'react'

type MenuItem = Required<MenuProps>['items'][number]

export const useMenuItems = (setOpen?: Dispatch<SetStateAction<boolean>>) => {
  const { logoutHandler } = useLogout()

  const items: MenuItem[] = [
    getItem(
      <Link to={ROUTES.panel} onClick={() => setOpen && setOpen(false)}>
        New
      </Link>,
      ROUTES.panel,
      <span className="material-icons ">add_box</span>,
    ),
    getItem(
      <Link to={ROUTES.reports} onClick={() => setOpen && setOpen(false)}>
        Reports
      </Link>,
      ROUTES.reports,
      <span className="material-icons">receipt_long</span>,
    ),
    // getItem(<Link to={ROUTES.loginHistory}>تاریخچه ورود</Link>, ROUTES.loginHistory, <span className="material-icons">update</span>),
    // getItem(<Link to={ROUTES.changePassword}>تغییر رمز عبور</Link>, ROUTES.changePassword, <span className="material-icons">lock</span>),
    getItem(
      <Link
        to={'#'}
        onClick={e => {
          e.preventDefault()
          logoutHandler()
        }}
      >
        <span>Logout</span>
      </Link>,
      'logout',
      <span className="material-icons">logout</span>,
    ),
  ]

  return { items }
}
