import { Layout } from 'antd'
import styled from 'styled-components'

const { Header } = Layout

export const MainLayoutHeaderContainer = styled(Header)<{
  homepage: string
}>`
  /* width: calc(100% - 210px); */
  z-index: 10;
  padding: 0 10px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 32px;
  ${props =>
    props.homepage === 'true'
      ? `
  background: transparent !important;
  position:fixed;
  top:0;
  `
      : ''}
  .navbar-wrapper {
    width: calc(100% - 210px);
    height: 100%;
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
  }
  .material-icons {
    cursor: pointer;
  }

  .navbar {
    height: 100%;
    width: 100%;
    padding: unset;
  }

  .navbar-nav {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }
  .navbar-logo {
    display: inline-flex;
    align-items: center;
  }
  .logo-title {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    padding: 0 0 0 10px;
  }
  .logo-icon {
    font-size: 30px;
  }
  .nav-link {
    margin-right: 30px;
    span {
      color: ${props => props.theme.colors.text.disabled};
    }
  }
  .navbar-fluid {
    width: 100%;
    height: 100%;
    display: inline-flex;
    justify-content: start;
    align-items: center;
  }
  .navbar-collapse {
    width: 100%;
  }
  .badge {
    position: absolute;
    top: 30%;
    left: 60%;
    translate: middle;
    padding: 2px;
    background-color: #ec8a2a;
    border: 1px solid #ec8a2a;
    border-radius: 50%;
  }
  .user-image {
    border: 2px solid #26a69a !important;
    border-radius: 50%;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    width: 32px;
    height: 32px;
    background-image: url('/assets/images/user.png');
    background-size: cover;
  }
  .user-image-dropdown.responsive {
    border: 2px solid #26a69a !important;
    border-radius: 50%;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    width: 32px;
    height: 32px;
    background-image: url('/assets/images/user.png');
    background-size: cover;
    display: none;
  }
  .header {
    font-family: 'IRANYekanEN';
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    width: 40%;
    height: 100%;
    text-align: left;
    color: var(--title-color-dark);
  }
  padding-inline: 24px !important;

  .sidebar-responsive-menu {
    display: none;
  }
  .tablet-desktop-dropdown {
    display: block;
  }
  .mobile-dropdown {
    display: none;
  }

  @media only screen and (max-width: 617px) {
    .header {
      width: 50%;
    }
    .nav-link {
      margin-right: 15px;
    }
  }

  @media only screen and (max-width: 576px) {
    .navbar-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .navbar {
      height: 100%;
      width: 50%;
      padding: unset;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .menu-responsive {
      display: block;
    }
    .header {
      width: 75%;
      /* text-align: center; */
      /* display: none; */
    }
    .tablet-desktop-dropdown {
      display: none;
    }
    .mobile-dropdown {
      display: block;
    }

    .user-image-dropdown.responsive {
      display: block;
      margin-top: 10px;
      .dropdown-menu {
        margin-top: 20px;
      }
    }
    .sidebar-responsive-menu {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
    }
  }
  @media only screen and (max-width: 400px) {
    /* padding-left: 3px; */
    .header {
      font-family: 'IRANYekanEN';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      width: 75%;
      height: 100%;
      text-align: center;
      color: var(--title-color-dark);
    }
    .navbar-fluid {
      margin-left: 0;
    }
  }

  @media only screen and (max-width: 593px) {
    /* padding: 0 5px !important; */

    .navbar-logo {
      padding-left: 20px;
    }
  }
`
