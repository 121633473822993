import { Col, Row, Spin } from 'antd'
import { IObject } from 'models/interfaces'
import { FC, useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { HomeService } from 'services/controllers/Home/Home.service'
import styled from 'styled-components'
import { IReportData } from 'services/controllers/User/models'

export interface IElectronicReceiptData extends IObject {
  transaction_serial_number: string
  payment_account_name: string
  payment_account_number: string
  recipient_account_name: string
  receiving_account_number: string
  name_of_receiving_bank: string
  amount: string
  currency: string
  business_type: string
  business_ID: string
  initiator: string
  trading_time: string
  bank_Name: string
  chinaNumber: string
}
export enum ECardsTitles {
  transaction_serial_number = '交易流水号:',
  payment_account_name = '付款户名:',
  payment_account_number = '付款账号:',
  recipient_account_name = '收款户名:',
  receiving_account_number = '收款账号:',
  name_of_receiving_bank = '收款行名:',
  amount = '金额:',
  currency = '币种:',
  business_type = '业务类型:',
  business_ID = '业务标识:',
  initiator = '发起机构:',
  trading_time = '交易时间:',
  bank_Name = '付款行名:',
}
export enum ECardsEnTitles {
  transaction_serial_number = 'Transaction Number:',
  payment_account_name = 'Payment Name:',
  payment_account_number = 'Payment Account Number:',
  recipient_account_name = 'Beneficiary Name:',
  receiving_account_number = 'Beneficiary Account Number:',
  name_of_receiving_bank = 'Beneficiary Bank Name:',
  amount = 'Amount:',
  currency = 'Currency:',
  business_type = 'Business Type:',
  business_ID = 'Business ID:',
  initiator = 'Sponsor:',
  trading_time = 'Transaction Time:',
  bank_Name = 'Payment bank name:',
  chinaNumber = 'chinaNumber:',
}
// export const ElectronicReceiptData: IElectronicReceiptData = {
//   transaction_serial_number: '95370251120230512',
//   payment_account_name: 'REMIS TRADING CO LIMITED',
//   payment_account_number: 'NRA290******3880',
//   recipient_account_name: 'KUMU LIMITED',
//   receiving_account_number: 'NRA145******0507',
//   name_of_receiving_bank: '金华银行股份有限公司',
//   amount: 'USD494,034.00',
//   currency: '美元',
//   business_type: '在线预约汇款转账',
//   business_ID: '0775',
//   initiator: '290000001',
//   trading_time: '2023-05-12 16:02:26',
//   bank_Name: '浙商银行上海自贸试验区分行',
// }
export const ElectronicReceipt: FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [params] = useSearchParams()
  const hashCode = params.get('hashCode')
  const [ElectronicReceiptData, setElectronicReceiptData] = useState<IReportData>()
  const fetchData = useCallback(async () => {
    if (hashCode) {
      setLoading(true)
      try {
        const { GetReceipt } = new HomeService()
        const result = await GetReceipt(hashCode, 'qrCode')
        if (result && result.data) {
          setElectronicReceiptData(result.data)
        }
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    }
  }, [hashCode])
  useEffect(() => {
    window.document.title = '浙商银行 电子回单'
    fetchData()
  }, [fetchData])

  return (
    <Spin spinning={loading}>
      <Row>
        <Header span={24}>
          <h1>浙商银行 电子回单</h1>
        </Header>
      </Row>
      <Container>
        <Col span={24}>
          <Row>
            <Content span={24}>
              {/* <Table dataSource={columns} /> */}
              {/* {data.map((item, index) => {
            const key = Object.keys(item)[0]
            const odd = index % 2 === 1
            return (
              <Row
                style={{
                  padding: '6.4px 8px',
                  background: odd ? '#fff' : 'rgba(0,0,0,.04)',
                }}
                key={index}
              >
                <Col span={24} className="item">
                  <h2 className="key">{key.trim().charAt(0).toUpperCase() + key.slice(1).replaceAll('_', ' ').concat(':')}</h2>
                  <h3 className="value">{' '.concat(item[key])}</h3>
                </Col>
              </Row>
            )
          })} */}
              <div className="table">
                <Row
                  className="content"
                  style={{
                    background: 'rgba(0,0,0,.04)',
                  }}
                >
                  <Col span={24} className="item">
                    <h2 className="key">{ECardsTitles.transaction_serial_number}</h2>
                    <h3 className="value">{' '.concat(ElectronicReceiptData?.transaction_serial_number || '')}</h3>
                  </Col>
                </Row>
                <Row
                  className="content"
                  style={{
                    background: '#fff',
                  }}
                >
                  <Col span={24} className="item">
                    <h2 className="key">{ECardsTitles.payment_account_name}</h2>
                    <h3 className="value">{' '.concat(ElectronicReceiptData?.payment_account_name || '')}</h3>
                  </Col>
                </Row>
                <Row
                  className="content"
                  style={{
                    background: 'rgba(0,0,0,.04)',
                  }}
                >
                  <Col span={24} className="item">
                    <h2 className="key">{ECardsTitles.payment_account_number}</h2>
                    <h3 className="value">{' '.concat(ElectronicReceiptData?.payment_account_number || '')}</h3>
                  </Col>
                </Row>
                <Row
                  className="content"
                  style={{
                    background: '#fff',
                  }}
                >
                  <Col span={24} className="item">
                    <h2 className="key">{ECardsTitles.bank_Name}</h2>
                    <h3 className="value">{' '.concat(ElectronicReceiptData?.bank_Name || '')}</h3>
                  </Col>
                </Row>
                <Row
                  className="content"
                  style={{
                    background: 'rgba(0,0,0,.04)',
                  }}
                >
                  <Col span={24} className="item">
                    <h2 className="key">{ECardsTitles.recipient_account_name}</h2>
                    <h3 className="value">{' '.concat(ElectronicReceiptData?.recipient_account_name || '')}</h3>
                  </Col>
                </Row>
                <Row
                  className="content"
                  style={{
                    background: '#fff',
                  }}
                >
                  <Col span={24} className="item">
                    <h2 className="key">{ECardsTitles.receiving_account_number}</h2>
                    <h3 className="value">{' '.concat(ElectronicReceiptData?.receiving_account_number || '')}</h3>
                  </Col>
                </Row>
                <Row
                  className="content"
                  style={{
                    background: 'rgba(0,0,0,.04)',
                  }}
                >
                  <Col span={24} className="item">
                    <h2 className="key">{ECardsTitles.name_of_receiving_bank}</h2>
                    <h3 className="value">{' '.concat(ElectronicReceiptData?.name_of_receiving_bank || '')}</h3>
                  </Col>
                </Row>
                <Row
                  className="content"
                  style={{
                    background: '#fff',
                  }}
                >
                  <Col span={24} className="item">
                    <h2 className="key">{ECardsTitles.amount}</h2>
                    <h3 className="value">{' '.concat(ElectronicReceiptData?.amount || '')}</h3>
                  </Col>
                </Row>
                <Row
                  className="content"
                  style={{
                    background: 'rgba(0,0,0,.04)',
                  }}
                >
                  <Col span={24} className="item">
                    <h2 className="key">{ECardsTitles.currency}</h2>
                    <h3 className="value">{' '.concat(ElectronicReceiptData?.currency || '')}</h3>
                  </Col>
                </Row>
                <Row
                  className="content"
                  style={{
                    background: '#fff',
                  }}
                >
                  <Col span={24} className="item">
                    <h2 className="key">{ECardsTitles.business_type}</h2>
                    <h3 className="value">{' '.concat(ElectronicReceiptData?.business_type || '')}</h3>
                  </Col>
                </Row>
                <Row
                  className="content"
                  style={{
                    background: 'rgba(0,0,0,.04)',
                  }}
                >
                  <Col span={24} className="item">
                    <h2 className="key">{ECardsTitles.business_ID}</h2>
                    <h3 className="value">{' '.concat(ElectronicReceiptData?.business_ID || '')}</h3>
                  </Col>
                </Row>
                <Row
                  className="content"
                  style={{
                    background: '#fff',
                  }}
                >
                  <Col span={24} className="item">
                    <h2 className="key">{ECardsTitles.initiator}</h2>
                    <h3 className="value">{' '.concat(ElectronicReceiptData?.initiator || '')}</h3>
                  </Col>
                </Row>
                <Row
                  className="content"
                  style={{
                    background: 'rgba(0,0,0,.04)',
                  }}
                >
                  <Col span={24} className="item">
                    <h2 className="key">{ECardsTitles.trading_time}</h2>
                    <h3 className="value">{' '.concat(ElectronicReceiptData?.trading_time || '')}</h3>
                  </Col>
                </Row>
              </div>
            </Content>
          </Row>
        </Col>
      </Container>
    </Spin>
  )
}

const Header = styled(Col)`
  background-color: #b60715;
  min-height: 1.1em;
  text-align: center;
  display: block;
  padding: 13.5px 0;

  h1 {
    white-space: nowrap;
    outline: 0 !important;
    text-overflow: ellipsis;
    text-align: center;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin: 0;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    vertical-align: inherit;
    letter-spacing: 0.1;
    color: #fff;
  }
`
export const Content = styled(Col)`
  background-color: #fff;
  padding: 16px;
  border: 1px solid #dddddd;
  display: inline;
  .item {
    display: flex;
    align-items: center;
    gap: 14px;
    padding: 6.4px 8px;
  }
  .table {
    border: 1px solid #dddddd;
    width: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  }
  .key {
    margin: 0;
    line-height: 1.5rem;
    text-align: left;
    font-weight: bold;
    font-size: 16px;
    font-weight: bold;
    vertical-align: inherit;
    letter-spacing: 0.1;
    color: #333333;
    text-shadow: 0 1px 0 #f3f3f3;
  }
  .value {
    margin: 0;
    color: #333333;
    text-shadow: 0 1px 0 #f3f3f3;
    line-height: 1.5rem;
    text-align: left;
    vertical-align: inherit;

    font-size: 1rem;
  }
`
export const Container = styled(Row)`
  /* padding: 16px; */
  height: calc(100vh - 45.39px);
  background-color: #f9f9f9;
`

// const data: IObject[] = [
//   {
//     transaction_serial_number: '95370251120230512',
//   },
//   {
//     payment_account_name: 'REMIS TRADING CO LIMITED',
//   },
//   {
//     payment_account_number: 'NRA290******3880',
//   },
//   {
//     recipient_account_name: 'Zheshang Bank Shanghai Pilot Free Trade Zone Branch',
//   },
//   {
//     receiving_account_number: 'KUMU LIMITED',
//   },
//   {
//     name_of_receiving_bank: 'NRA145******0507',
//   },
//   {
//     amount: 'Jinhua Bank Co., Ltd',
//   },
//   {
//     currency: 'USD494,034.00',
//   },
//   {
//     business_type: 'Business type',
//   },
//   {
//     business_ID: '0775',
//   },
//   {
//     initiator: '290000001',
//   },
//   {
//     trading_time: '2023-05-12 16:02:26',
//   },
// ]

// const columns = [
//   {
//     title: 'Transaction serial number',
//     dataIndex: 'transaction_serial_number',
//     key: 'transaction_serial_number',
//   },
//   {
//     title: 'Payment account name',
//     dataIndex: 'payment_account_name',
//     key: 'payment_account_name',
//   },
//   {
//     title: 'Payment account number',
//     dataIndex: 'payment_account_number',
//     key: 'payment_account_number',
//   },
//   {
//     title: 'Recipient account name',
//     dataIndex: 'recipient_account_name',
//     key: 'recipient_account_name',
//   },
//   {
//     title: 'Receiving account number',
//     dataIndex: 'receiving_account_number',
//     key: 'receiving_account_number',
//   },
//   {
//     title: 'Name of receiving bank',
//     dataIndex: 'name_of_receiving_bank',
//     key: 'name_of_receiving_bank',
//   },
//   {
//     title: 'Amount',
//     dataIndex: 'amount',
//     key: 'amount',
//   },
//   {
//     title: 'Currency',
//     dataIndex: 'currency',
//     key: 'currency',
//   },
//   {
//     title: 'Business type',
//     dataIndex: 'business_type',
//     key: 'business_type',
//   },
//   {
//     title: 'Business ID',
//     dataIndex: 'business_ID',
//     key: 'business_ID',
//   },
//   {
//     title: 'Initiator',
//     dataIndex: 'initiator',
//     key: 'initiator',
//   },
//   {
//     title: 'Trading time',
//     dataIndex: 'trading_time',
//     key: 'trading_time',
//   },
// ]
