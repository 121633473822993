import styled from 'styled-components'
import { Drawer } from 'antd'

export const DrawerContainer = styled(Drawer)`
  .ant-menu {
    background-color: transparent;
  }
  .ant-menu-item,
  .ant-menu-item-selected {
    border: none;
    background-color: transparent;
  }
  .ant-menu-title-content {
    background-color: transparent;
    a {
      font-size: 24px !important;
    }
  }
  .ant-menu-item a {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }
  .ant-drawer-header {
    padding: 10px;
  }
  .ant-drawer-header-title {
    display: inline-flex;
    flex-direction: row-reverse;
    padding: 0;
  }
  .ant-drawer-body {
    padding: 0;
  }
`

export const MenuContainer = styled.div`
  background: ${props => props.theme.colors.components.footerBg} !important;
  .menu {
    background: ${props => props.theme.colors.components.footerBg} !important;
  }
  .ant-menu-item,
  .ant-menu-item-selected {
    border: none;
  }
  .ant-menu-title-content {
    /* background-color: transparent; */
    font-size: 18px !important;
    a {
      font-size: 18px !important;
    }
  }
  .material-icons {
    font-size: 20px !important;
  }
  .ant-menu-item a {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }
`
