import { USER_JWT_TOKEN } from 'constants/localStorage'
import { ROUTES } from 'models/enums'
import { useNavigate } from 'react-router-dom'
import useAuthStore from 'store/auth'

export const useLogin = () => {
  const navigate = useNavigate()
  const { login } = useAuthStore()

  const loginHandler = async (token: string) => {
    localStorage.setItem(USER_JWT_TOKEN, token)
    login(token)
    navigate(ROUTES.panel)
  }

  return { loginHandler }
}
