import { FC } from 'react'
import { Tooltip } from 'antd'
import { useDownloadExcel } from 'react-export-table-to-excel/lib/hooks/useExcel'
import { Icons } from './customIcons'
// import ReactToPrint from 'react-to-print'

interface IExcelPdfOutputProps {
  name?: string
  tableRef: any
}

const ExcelPdfOutput: FC<IExcelPdfOutputProps> = ({ tableRef, name = 'report' }) => {
  // const reactToPrintContent = useCallback(() => {
  //   return tableRef.current
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [tableRef.current])

  // const reactToPrintTrigger = useCallback(() => {
  //   return (
  //     <Tooltip placement="bottom" title="خروجی پی دی اف" className="output-tooltip">
  //       <span className="output-pdf">
  //         <Icons name="pdfOutput" />
  //       </span>
  //     </Tooltip>
  //   )
  // }, [])

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: name,
    sheet: 'ECZ Bank',
  })

  return (
    <div className="output cursor-pointer">
      {/* <ReactToPrint content={reactToPrintContent} documentTitle="AwesomeFileName" trigger={reactToPrintTrigger} /> */}

      <Tooltip placement="bottom" title="Export Excel" className="output-tooltip" color="#23272E">
        <span className="output-pdf" onClick={onDownload}>
          <Icons name="excelOutput" />
        </span>
      </Tooltip>
    </div>
  )
}

export default ExcelPdfOutput
