import Swal from 'sweetalert2'

interface IArgs {
  type: 'success' | 'error'
  text?: string
  onOk?: () => void
}

export const SwalََAlert = (args: IArgs) => {
  switch (args.type) {
    case 'success':
      Swal.fire({
        title: 'Success',
        text: args.text || 'The action was successful',
        color: '#25E3BF',
        confirmButtonText: 'Close',
        background: '#0b0e11',
        iconHtml: '<img src="/assets/images/sweet-alert/sweetalert-success.png" width="53px" height="53px">',
      }).then(() => args.onOk && args.onOk())
      break

    case 'error':
      Swal.fire({
        title: 'Error',
        text: args.text || 'The action was unsuccessful',
        color: '#F04849',
        confirmButtonText: 'Close',
        background: '#0b0e11',
        iconHtml: '<img src="/assets/images/sweet-alert/sweetalert-error.png" width="53px" height="53px">',
      }).then(() => args.onOk && args.onOk())
      break
  }
}
